define({
    // Cloud Center Strings
    root: {
        // index page
        indexPageTitle: `Sign in - Cloud Center`,
        indexPageToggleNavigation: `Toggle Main Navigation`,
        indexPageStoreAnywhere: `Store, access and manage your files from anywhere.`,
        indexPageLearnMore: `Learn about MathWorks Cloud Center.`,
        indexPageInstallConnector: `Install MATLAB Drive Connector.`, //gs: used?
        loggedInPageTitle: `Cloud Center`,
        rootFolderNameMatlabDrive: `MATLAB Drive`, //GS: used?
        incompatibleBrowser: `Incompatible Browser`,
        moreFolders: `More folders`,
        footerPatents: `Patents`,
        footerTrademarks: `Trademarks`,
        footerPrivacy: `Privacy Policy`,
        footerPiracy: `Preventing Piracy`,
        policyName: `Policy Name`,

        // Cloud Center general
        cloudCenterLogoText: `Cloud Center`,
        cloudCenterLoginText: `MathWorks Cloud Center`,
        computeLinkText: `Cloud Resources`,
        credentialLinkText: `Cloud Accounts`,
        requiredValue: `Required`,
        unknownValue: `Unknown`,
        noNameSpecified: `No name`,
        parenthesisDefault: `(default)`,
        notifications: `Notifications`,
        releaseNeedsLicenseWarning: `Cloud Center is unable to detect a license for
    your MathWorks account for the selected release.
    Supply a license when you access your cloud resource.`,
        moreInformation: `More information`,
        showOrHidePassword: `Show or hide password`,
        accessSettings: `IP And Access Ports`,
        viewNotifications: `View notifications`,
        copyButtonTooltip: `Copy value to clipboard`,
        platformNotSupported: `Platform not supported: \${0}`,

        // All pages in general
        pageUserFirstName: `Files`,
        pageToggleNavigation: `Toggle Main Navigation`,
        pageAboutLabel: `About`,
        pageManagementLabel: `Manage`,
        pageLogoutLabel: `Sign Out`,
        pageHelpLabel: `Help`,
        myAccountLabel: `My Account`,
        optional: `(Optional)`,
        dataNotAvailable: `Data unavailable`,
        failedToGetData: `Unable to get required data`,
        notEntitled: `(license required)`,

        //Cloud Account Generic
        cloudAccount_awsiam: `Amazon AWS`,
        cloudAccount_microsoft_azure: `Microsoft Azure`,
        cloudAccount_workflow: `Start and manage cloud resources`,

        // Supported Product Display Names
        supportedProductDisplayName_matlab: `MATLAB`,
        supportedProductDisplayName_online_server: `DevOps Software Factory Demo`,
        supportedProductDisplayName_parallel_server: `MATLAB Parallel Server`,
        supportedProductDisplayName_web_app_server: `MATLAB Web App Server`,
        supportedProductDisplayName_production_server: `MATLAB Production Server`,
        supportedProductDisplayName_cluster: `MATLAB Parallel Server`,
        supportedProductDisplayName_license_manager: `Network License Manager`,
        supportedProductDisplayName_cfe_demo: `AE and CFE Demos`,
        supportedProductDisplayName_cloud_network: `Cloud Network`,

        // Supported Product Proxy URL Prefixes
        supportedProductURLPrefix_matlab: `matlab`,
        supportedProductURLPrefix_online_server: `demo`,
        supportedProductURLPrefix_parallel_server: `matlab-parallel-server`,
        supportedProductURLPrefix_web_app_server: `matlab-web-app-server`,
        supportedProductURLPrefix_production_server: `matlab-production-server`,
        supportedProductURLPrefix_cluster: `matlab-parallel-server`,
        supportedProductURLPrefix_license_manager: `license-manager`,
        supportedProductURLPrefix_cfe_demo: `demo`,
        supportedProductURLPrefix_cloud_network: `resource`,


        // about page
        aboutAvailableProducts: `Available Products`,
        aboutProductName: `Product`,
        aboutVersion: `Version`,
        aboutProviderName: `Provider (Operating System)`,
        aboutOperatingSystem: `Operating System`,
        aboutLocation: `Cloud Location(s)`,
        aboutDescription: `Description`,
        aboutDeprecated: `Deprecated`,
        aboutDeprecatedYes: `Yes`,
        aboutDeprecatedNo: `No`,

        aboutThirdPartyLicenseTerms: `Third-party License Terms`,
        aboutProvider: `Provider`,
        aboutService: `Service`,
        aboutLink: `Provider Terms`,


        // management page
        managementCloudAccounts: `Accounts`,
        managementAccountId: `Description`,
        managementAccountName: `Name`,
        managementLoadingAccounts: `Please wait ... if this is your first time visiting this page, the data cache may not exist, and you may experience a timeout.  If so, reload this page and you should start to see your data coming in.  Rapid or frequent page reloads will not help the cache load faster.`,
        managementNoAccountsFound: `You are not labeled in Cloud Center as an owner for any accounts.`,



        // compute page
        computeResourcePageNoResourcesFound: `To start a new MATLAB cloud resource, click Create.`,
        computeResourcePageNoClustersFound: `To start a new MATLAB Parallel Server cluster, click Create.`, //legacy
        computeResourcePageNoParallelServersFound: `To start a new MATLAB Parallel Server cluster, click Create.`, //modern
        computeResourcePageNoProductionServersFound: `To start a new MATLAB Production Server, click Create.`,
        computeResourcePageNoLicenseManagersFound: `To start a new Network License Manager, click Create.`,
        computeResourcePageNoWebAppServersFound: `To start a new MATLAB Web App Server, click Create.`,
        computeResourcePageNoOnlineServersFound: `To start the DevOps Software Factory Demo, click Create.`,
        computeResourcePageNoCfeDemosFound: `To start a demo, click Create.`,
        computeResourcePageNoCloudNetworkFound: `To setup cloud network, click Create.`,
        computeResourcePageErrorManualRefreshRequired: `An error occurred updating the status of your resource. Manually refresh this page to update the status.`,
        computeResourcePageResourcesLabel: `MATLAB`,
        computeResourcePageClusterLabel: `MATLAB Parallel Server`, //legacy
        computeResourcePageAWSClusterLabel: `MATLAB Parallel Server on AWS`,
        computeResourcePageParallelServerLabel: `MATLAB Parallel Server`, //modern
        computeResourcePageAzureParallelServerLabel: `MATLAB Parallel Server on Azure`,
        computeResourcePageProductionServerLabel: `MATLAB Production Server`,
        computeResourcePageLicenseManagerLabel: `Network License Manager`,
        computeResourcePageOnlineServerLabel: `DevOps Software Factory Demo`,
        computeResourcePageCfeDemoLabel: `AE and CFE Demos`,
        computeResourcePageCloudNetworkLabel: `Cloud Network`,
        computeResourcePageWebAppServerLabel: `MATLAB Web App Server`,
        computeResourcePageStartButtonLabel: `Create`,
        computeResourcePageCreateNewMatlabLabel: `Create`,
        computeResourcePageCreateNewMatlabFinePrint: `Cloud resource with single machine running MATLAB`,
        computeResourcePageCreateNewClusterLabel: `Create`, //legacy
        computeResourcePageCreateNewParallelServerLabel: `Create`, //modern
        computeResourcePageCreateNewProductionServerLabel: `Create`,
        computeResourcePageCreateNewLicenseManagerLabel: `Create`,
        computeResourcePageCreateNewOnlineServerLabel: `Create`,
        computeResourcePageCreateNewCfeDemoLabel: `Create`,
        computeResourcePageCreateNewCloudNetworkLabel: `Create`,
        computeResourcePageCreateNewParallelServerFinePrint: `Cloud resource with running a MATLAB Parallel Server`, //modern
        computeResourcePageCreateNewProductionServerFinePrint: `Cloud resource with running a MATLAB Production Server`,
        computeResourcePageCreateNewLicenseManagerFinePrint: `Cloud resource with running a Network License Manager`,
        computeResourcePageCreateNewOnlineServerFinePrint: `Cloud resource with running the DevOps Software Factory Demo`,
        computeResourcePageCreateNewCfeDemoFinePrint: `Cloud resource with running a preconfigured demo`,
        computeResourcePageCreateNewCloudNetworkFinePrint: `Setup cloud network for use with other cloud resources`,
        computeResourcePageCreateNewWebAppServerLabel: `Create`,
        computeResourcePageCreateNewWebAppServerFinePrint: `Cloud resource with running a MATLAB Web App Server`,
        computeResourcePageCreateNewCluserFinePrint: `Cloud resource with MATLAB Parallel Server cluster`, //legacy
        computeResourcePageDefaultResourcesMsg: `No cloud resource found.`,
        computeResourcePageIPNotApplicable: `Offline`,
        computeResourcePageMustHaveCredentialWarning: `To start a MATLAB cloud resource, you must first authorize Cloud Center to access your public cloud account.`,
        computeResourcePageOpeningParellelServerPageWarning: `Opening MATLAB Parallel Server Page...`,
        computeResourcePageDisplayResourceNotifications: `Display resource specific notifications`,

        computeResourcePageColumnHeaderName: `Name`,
        computeResourcePageColumnNotifications: `Notifications`,
        computeResourcePageColumnHeaderConnect: `Connect (xyz)`, //GS: Not used?
        computeResourcePageColumnHeaderIPAddress: `IP Address`,
        computeResourcePageColumnHeaderProduct: `Product`,
        computeResourcePageColumnHeaderRelease: `Release`,
        computeResourcePageColumnHeaderNetwork: `Network`,
        computeResourcePageColumnHeaderEnvironmentName: `Env Name`,
        computeResourcePageColumnHeaderCloudProvider: `Provider`,
        computeResourcePageColumnHeaderCloudLocation: `Cloud Location`,
        computeResourcePageColumnHeaderMachineType: `Machine Type`,
        computeResourcePageColumnHeaderOperatingSystem: `Operating System`,
        computeResourcePageColumnHeaderStatus: `Status`,
        computeResourcePageColumnHeaderActions: `Actions`,
        computeResourcePageColumnHeaderPrimaryAction: `Primary Action`,
        computeResourcePageColumnHeaderMenu: `Menu`,

        computeResourcePageActionStart: `Start`,
        computeResourcePageActionStartTooltip: `Start cloud resource`,
        computeResourcePageActionStop: `Reset`,
        computeResourcePageActionStopTooltip: `Reset cloud resource`,
        computeResourcePageActionPause: `Stop`,
        computeResourcePageActionPauseTooltip: `Stopping powers down the machine but continues to incur costs`,
        computeResourcePageActionResume: `Start`,
        computeResourcePageActionResumeTooltip: `Start cloud resource`,
        computeResourcePageActionArchive: `Delete`,
        computeResourcePageActionArchiveTooltip: `Delete cloud resource and configuration`,
        computeResourcePageActionEdit: `Edit`,
        computeResourcePageActionEditTooltip: `Edit cloud resource configuration`,
        computeResourcePageActionClone: `Duplicate`,
        computeResourcePageActionCloneTooltip: `Create copy of cloud resource configuration`,
        computeResourcePageActionCloneErrorWithInfo: `A server error occurred: \${0}. Try again later.`,
        computeResourcePageActionCloneSunsetErrorWithInfo: `Duplication is now allowed: \${0}.`,
        computeResourcePageActionCloneSuccessWithInfo: `\${0}: Successfully duplicated.`,
        computeResourcePageActionCreateSuccessWithInfo: `\${0}: Successfully created.`,
        computeResourcePageActionCreateSuccessWithoutInfoAndRefresh: `Successfully created cloud resource. Reload page to view.`,
        computeResourcePageActionCopy: `Copy`,
        computeResourcePageActionCopyTooltip: `Copy IP Address`,
        computeResourcePageActionDownloadRDP: `Download Remote Desktop File`,
        computeResourcePageActionDownloadRDPTooltip: `Download Remote Desktop .rdp file`,
        computeResourcePageActionDownloadDCV: `Download Nice DCV File`,
        computeResourcePageActionDownloadDCVTooltip: `Download Nice DCV .dcv file`,
        computeResourcePageActionCopyPassword: `Password`,
        computeResourcePageActionCopyCloudLink: `\${0}`,
        computeResourcePageActionCopyCloudLinkTooltip: `Copy link for \${0} to clipboard`,
        computeResourcePageActionCopyPasswordTooltip: `Copy password to clipboard`,
        computeResourcePageActionCopyPasswordSuccess: `Password copied to clipboard`,
        computeResourcePageActionCopyCloudLinkSuccess: `Copied to clipboard: Link for \${0}.`,
        computeResourcePageActionShareResourceSettings: `Resource Configuration Settings`,
        computeResourcePageActionShareResourceSettingsTooltip: `Copy resource configuration settings URL to clipboard`,
        computeResourcePageActionShareResourceSettingsSuccess: `Resource configuration settings URL copied to clipboard`,
        computeResourcePageActionShareResourceSettingsFailure: `Unable to create resource. Add the cloud account specified in the URL to Cloud Center and try again, or contact your IT Administrator for more information.`,
        computeResourcePagePopupWithLoginInfo: `Copy this user name and password.  You need them to log in to your cloud resource.`,
        computeResourcePagePopupWithLoginInfoUsername: `User name`,
        computeResourcePagePopupWithLoginInfoPassword: `Password`,
        computeResourcePageCopyNotSupported: `Copy to clipboard not supported in this browser.`,
        computeResourcePageActionOutputsSubmenuTitle: `Copy`,

        computeResourcePagePopupWithPopupInfo: `If a new browser tab does not open, check for pop-up blockers.`,
        computeResourcePageActionConnect: `Open MATLAB`,
        computeResourcePageActionConnectDashboard: `Open Dashboard`,

        dynamicConnectBtn_webdesktop: 'Open MATLAB via Hosted URL',
        dynamicConnectBtn_webdesktopdirect: 'Open MATLAB via Direct URL',
        dynamicConnectBtn_nicedcvdesktopdirect: `Open MATLAB Desktop via Direct URL`,
        dynamicConnectBtn_nicedcvdesktop: `Open MATLAB Desktop via Hosted URL`,
        dynamicConnectBtn_nicedcv: `Open MATLAB`,
        dynamicConnectBtnTooltip_nicedcv: `Open cloud resource in browser to access MATLAB`,
        dynamicConnectBtn_nicedcvdirect: `Open MATLAB (Direct)`,
        dynamicConnectBtnTooltip_nicedcvdirect: `Open cloud resource in browser to access MATLAB`,
        dynamicConnectBtn_rdp: `Download Remote Desktop File`,
        dynamicConnectBtnTooltip_rdp: `Download Remote Desktop .rdp file`,
        dynamicConnectBtn_nicedcvfile: `Download Nice DCV File`,
        dynamicConnectBtnTooltip_nicedcvfile: `Download Nice DCV .dcv file`,
        dynamicConnectBtn_clusterprofile: `Download Cluster Profile`,
        dynamicConnectBtnTooltip_clusterprofile: `Download Cluster Profile MATLAB Settings file`,
        dynamicConnectBtn_admin_console: `Open Admin Console`,
        dynamicConnectBtn_admin_console_direct: `Open Admin Console (Direct)`,
        dynamicConnectBtnTooltip_admin_console: `Open Admin Console`,
        dynamicConnectBtn_execution_url: `Open MATLAB Execution URL`,
        dynamicConnectBtnTooltip_execution_url: `Open MATLAB Execution URL`,
        dynamicConnectBtn_license_manager: `Open License Manager`,
        dynamicConnectBtn_license_manager_direct: `Open License Manager (Direct)`,
        dynamicConnectBtnTooltip_license_manager: `Open Network License Management Server`,
        dynamicConnectBtn_home_page: `Open Home Page`,
        dynamicConnectBtnTooltip_home_page: `Open Web App Server Home Page`,
        dynamicConnectBtn_s3_bucket: `Open AWS s3 Bucket`,
        dynamicConnectBtnTooltip_s3_bucket: `Open s3 Bucket`,
        dynamicConnectBtn_MATLABProductionServerDashboardURL: `URL of Admin Console`,
        dynamicConnectBtnTooltip_MATLABProductionServerDashboardURL: `Copy URL of Admin Console to clipboard`,
        dynamicConnectBtn_MATLABProductionServerAdminVMLogGroup: `URL of CloudWatch Log Group for Admin VM`,
        dynamicConnectBtnTooltip_MATLABProductionServerAdminVMLogGroup: `Copy URL of CloudWatch Log Group for Admin VM to clipboard`,
        dynamicConnectBtn_MATLABProductionServerWorkerVMLogGroup: `URL of CloudWatch Log Group for Worker VM`,
        dynamicConnectBtnTooltip_MATLABProductionServerWorkerVMLogGroup: `Copy URL of CloudWatch Log Group for Worker VM to clipboard`,
        dynamicConnectBtn_MATLABWebAppServerEC2Instance: `URL of EC2 Instance`,
        dynamicConnectBtnTooltip_MATLABWebAppServerEC2Instance: `Copy URL of Web App Server EC2 Instance to clipboard`,
        dynamicConnectBtn_MATLABWebAppServerAppsS3Bucket: `URL of S3 Bucket`,
        dynamicConnectBtnTooltip_MATLABWebAppServerAppsS3Bucket: `Copy URL of Web App Server S3 Bucket to clipboard`,
        dynamicConnectBtn_MATLABWebAppServerLogGroup: `URL of Web App Server Log Files`,
        dynamicConnectBtnTooltip_MATLABWebAppServerLogGroup: `Copy URL of Web App Server Log Files to clipboard`,
        dynamicConnectBtnTooltip_web_app_admin_console: `Keycloak Console`,
        dynamicConnectBtnTooltip_web_app_admin_console_direct: `Keycloak Console (Direct)`,
        dynamicConnectBtnTooltip_web_app_cloud_console: `Cloud Console`,
        dynamicConnectBtn_web_app_admin_console: `Keycloak Console`,
        dynamicConnectBtn_web_app_admin_console_direct: `Keycloak Console (Direct)`,
        dynamicConnectBtn_web_app_cloud_console: `Cloud Console`,
        dynamicConnectBtn_web_app_app_home: `MATLAB Web App Server Apps Home`,
        dynamicConnectBtnTooltip_web_app_app_home: `MATLAB Web App Server Apps Home`,
        dynamicConnectBtn_web_app_app_home_direct: `MATLAB Web App Server Apps Home (Direct)`,
        dynamicConnectBtnTooltip_web_app_app_home_direct: `MATLAB Web App Server Apps Home (Direct)`,
        dynamicConnectBtn_web_app_apps_s3_bucket: `MATLAB Web App Server Apps S3 Bucket`,
        dynamicConnectBtnTooltip_web_app_apps_s3_bucket: `MATLAB Web App Server Apps S3 Bucket`,
        dynamicConnectBtn_forceresume: `Force Restart`,
        dynamicConnectBtnTooltip_forceresume: `Attempt to continue to restart after errors for up to one hour.`,
        dynamicConnectBtn_web_app_server_admin_portal: `MATLAB Web App Server Admin Console`,
        dynamicConnectBtn_web_app_server_admin_portal_direct: `MATLAB Web App Server Admin Console (Direct)`,

        // CCInternalScripts - forceresume
        forceresume_introduction: `\${0}: The process will repeatedly attempt to restart the machine up to a max of \${1} attempts. There's a delay of \${2} seconds between attempts.`,
        forceresume_successful_start: `\${0}: The machine should be starting or running already.`,
        forceresume_retrying_start: `\${0}: Attempting (\${1} out of max \${2}) to restart machine after waiting the specified interval.`,
        forceresume_reached_max: `\${0}: Reached max \${1} restart attempts.  Aborting process.`,
        forceresume_process_aborted_due_to_error: `\${0}: Process aborted due to error \${1}. See console.`,

        computeResourcePageActionProductionServerDashboardTooltip: `Open MATLAB Production Server Admin Dashboard in Browser`,
        computeResourcePageActionOnlineServerTooltip: `Open DevOps Software Factory Demo in Browser`,
        computeResourcePageActionCfeDemoTooltip: `Open Demo in Browser`,
        computeResourcePageActionParallelServerTooltip: `Download cluster profile`,
        computeResourcePageActionWebAppServerDashboardTooltip: `Open Web App Server Admin Dashboard in Browser`,
        computeResourcePageActionConnectLicenseServer: `Open MathWorks Network License Manager Server`,
        computeResourcePageActionLicenseServerTooltip: `Open MathWorks Network License Server in Browser`,
        computeResourcePageActionConnectS3Bucket: `Open S3 Bucket`,
        computeResourcePageActionConnectTooltip: `Open cloud resource in browser to access MATLAB`,
        computeResourcePageActionConnectTooltipGeneric: `Open cloud resource in browser`,
        computeResourcePageActionConnectTooltipWebAppServer: `Open Web App Server App Home`,
        computeResourcePageActionConnectTooltipParallelServer: `Download cluster profile`,
        computeResourcePageActionMoreOptions: `More Options`,
        computeResourcePageActionMoreOptionsTooltip: `Expand for more options`,

        computeResourceLaunchInfo: `Your cloud resource is starting. This can take 5-15 minutes.`,
        computeResourcePageStatusStarted: `Running`,
        computeResourcePageStatusStopped: `Stopped`,
        //used for 2 states
        computeResourcePageStatusStopping: `Deleting`,
        computeResourcePageStatusPausing: `Stopping`,
        computeResourcePageStatusPaused: `Stopped`,
        computeResourcePageStatusInitializing: `Starting`,
        computeResourcePageStatusUpdating: `Updating`, //used for transitioning from running <-> stopped as well as start <-> stop
        computeResourcePageStatusUnknown: `Unknown`,

        computeResourcePageDeleteInProgress: `Delete In Progress...`,
        computeResourcePageDeleteComplete: `Successfully deleted.`,

        computeResourcePageConfirmStopDialogTitle: `Confirm Reset`,
        computeResourcePageConfirmStopProceedPrompt: `Are you sure you want to reset this cloud resource?`,
        computeResourcePageConfirmStopDialogPromptText: `Reset removes all items under this cloud resource,
    any customizations are lost as a result.
    If you independently created storage or other resources, those will not be deleted.
    If you want to keep your customizations but turn off the machine, choose Stop.`,

        computeResourcePageConfirmPauseDialogTitle: `Confirm Stop`,
        computeResourcePageConfirmPauseProceedPrompt: `Are you sure you want to stop this machine?`,

        computeResourcePageConfirmPauseDialogPromptText: `Stop powers down your machine. Any unsaved changes are lost but machine customizations persist.
    You are not charged machine usage for a stopped machine, but you incur other charges, such as storage, until you delete. `,

        computeResourcePageConfirmStopTemplateOnlyDialogPromptText: `Stop deallocates the cloud resource from the cloud provider but leaves the record in Cloud Center.  Use delete to both deallocate the cloud resource from the cloud provider and delete the record from Cloud Center.`,
        computeResourcePageConfirmStopTemplateOnlyProceedPrompt: `Are you sure you want to deallocate this cloud resource from the cloud provider?`,


        computeResourcePageConfirmDeleteDialogTitle: `Delete Cloud Resource`,
        computeResourcePageConfirmDeleteProceedPrompt: ``,
        computeResourcePageConfirmDeleteDialogPromptText: `Deleting this cloud resource will remove it from your list of available resources.`,
        computeResourcePageConfirmDeleteDialogExitButtonLabel: `Delete Anyway`,

        computeResourcePageConfirmUnknownDialogTitle: `Confirm `,
        computeResourcePageConfirmUnknownProceedPrompt: `Are you sure you want to `,
        computeResourcePageConfirmUnknownDialogPromptText: ``,


        computeResourcePageAddAccessText: `Access added via Cloud Center`,
        computeResourcePageRDPAddAccessText: `Remote Desktop access added via Cloud Center`,
        computeResourcePageClusterProfileAddAccessText: `Cluster access added via Cloud Center`,
        computeResourcePageRDPAddAccessSuccess: `Added access to cloud resource's inbound firewall.`,

        computeResourcePageErrorLoadingInitialPage: `Unable to load list of cloud resources. Refresh this page. `,

        computeResourcePageServerError: `Server Error. Try again later. `,
        computeResourcePageClusterServerError: `Error loading cluster list. Try again later. `,
        computeResourcePageClusterServerNetworkError: `Unable to load list of cloud resources due to network error. `,

        cloudProviderDisplayName_aws: `Amazon AWS`,
        cloudProviderDisplayName_azure: `Microsoft Azure`,

        operatingSystem_linux: `Linux`,
        operatingSystem_windows: `Windows`,

        terminationPolicyButtonSubtext: `Timeout`,
        terminationPolicyTimeoutRemaining: "remaining",
        terminationPolicyWarning: `Cloud resource shuts down automatically at`,
        terminationPolicyResetTimerButton: `Apply`,
        terminationPolicyResetTimerButtonTooltip: `Extend the time until your cloud resource shuts down automatically.`,
        providerResourceStatusLabel: `Provider Resources:`,
        copyIPValueButtonTooltip: `Copy IP to clipboard.`,
        copyOutputValueButtonLabel: `Copy value`,
        copyOutputValueButtonTooltip: `Copy to clipboard.`,
        connectButtonTooltip: `Connect to cloud resource.`,
        startButtonTooltip: `Start cloud resource.`,
        resumeButtonTooltip: `Start cloud resource.`,
        pauseButtonTooltip: `Stop cloud resource.`,
        stopButtonTooltip: `Stop cloud resource.`,
        deleteButtonTooltip: `Delete cloud resource.`,
        editButtonTooltip: `Edit cloud resource configuration`,
        downloadRDPButtonTooltip: `Download remote desktop RDP file.`,
        cloneButtonTooltip: `Create a copy of this cloud resource configuration.`,
        createMatlabButtonTooltip: `Create a cloud resource configuration`,
        createClusterButtonTooltip: `Create a MATLAB Parallel Server® cluster.`, //legacy
        createParallelServerButtonTooltip: `Create a MATLAB Parallel Server® cluster.`, //modern
        createProductionServerButtonTooltip: `Create a MATLAB Production Server® configuration.`,
        createLicenseManagerButtonTooltip: `Create a Network License Manager® configuration.`,
        createOnlineServerButtonTooltip: `Create the DevOps Software Factory Demo.`,
        createCfeDemoButtonTooltip: `Create a preconfigured demo in the AE or CFE account.`,
        createCloudNetworkButtonTooltip: `Setup cloud network to use with other cloud resources.`,
        createWebAppServerButtonTooltip: `Create a MATLAB Web App Server® configuration.`,
        timeoutPolicyLabel: `Auto-Shutdown`,
        terminationPolicyUpdateError: `Unable to update termination policy.`,


        // compute details page
        providerResourceNotAllocated: `not allocated`,
        providerResourceAllocated: `allocated`,
        additionalDetailsToggleTooltip: `Expand or collapse Additional Details section`,
        computeDetailsReleaseOsRegionTitle: `Release, Operating System and Region`,
        computeDetailsCloudResourceConfigTitle: `Configuration`,
        computeDetailsNameProductSubTitle: `Name and Product`,
        computeDetailsAccessListTitle: `Access Ports and IP`,
        computeDetailsMachineListTitle: `Machine List`,
        computeDetailsOutputListTitle: `Outputs`,
        computeResourceDetailPageStart: `Start`,
        computeResourceDetailPageStop: `Reset (xyz)`, //GS: Doesn't seem used
        computeResourceDetailPageRefresh: `Refresh`,
        computeResourceDetailPageDeactivate: `Delete (xyz)`, //GS: Doesn't seem used
        computeResourceDetailPageAddIpLink: `Add Access`,
        computeDetailsLabelDeleteAccess: `Delete Access`,
        computeDetailsLabelAddNewAccess: `Add New Access`,
        computeDetailsWaitWhileResourceStarts: `Please wait while we commence starting your cloud resource...`,
        computeDetailsInProgressStatusStarting: `Your cloud resource is starting. This can take 5-15 minutes.`,
        computeDetailsInProgressStatusStopping: `Your cloud resource is stopping. This can take 5-15 minutes.`,
        computeDetailsInProgressStatusResetting: `Your cloud resource is resetting. This can take 5-15 minutes.`,
        computeResourceDetailPageStartSuccess: `Your cloud resource is starting. This can take 5-15 minutes.`,
        computeResourceDetailPageStopSuccess: `Resetting your cloud resource: '\${0}'`,
        computeResourceDetailPageArchiveSuccess: `Your cloud resource '\${0}' has been deleted. Redirecting to Cloud Resources.`,
        computeResourceDetailPageResumeSuccess: `Your cloud resource '\${0}' is starting. This can take 5-15 minutes.`,
        computeResourceDetailPagePauseSuccess: `Stopping your cloud resource: '\${0}'`,
        computeResourceDetailPageIPLabel: `IP: `,
        computeResourceDetailPagePublicIPLabel: `Public IP: `,
        computeResourceDetailPagePrivateIPLabel: `Private IP: `,
        computeDetailsNoAvailableIP: `Offline`,
        computeDetailsLabelName: `Name: `,
        computeDetailsLabelRelease: `Release: `,
        computeDetailsLabelCloudPlatform: `Cloud platform: `,
        computeDetailsLabelCredId: `Cloud Account ID: `,
        computeDetailsLabelOS: `Operating system: `,
        computeDetailsLabelRegion: `Cloud Location: `,
        computeDetailsLabelInstanceName: `Instance name: `,
        computeDetailsLabelDescription: `Description: `,
        computeDetailsLabelVPC: `VPC: `,
        computeDetailsLabelVPCIPv4: `IPv4: `,
        computeDetailsLabelVPCisDefault: `Is default: `,
        computeDetailsLabelVPCName: `Name: `,
        computeDetailsLabelSubnet: `Subnet: `,
        computeDetailsLabelSubnetAvailZone: `Availability zone: `,
        computeDetailsLabelSubnetAvailZoneID: `Availability zone ID: `,
        computeDetailsLabelSubnetIPv4: `IPv4: `,
        computeDetailsLabelSubnetIsDefault: `Is default: `,
        computeDetailsLabelSubnetType: `Type: `,
        computeDetailsLabelSubnetName: `Name: `,
        computeDetailsLabelInstanceType: `Instance type: `,
        computeDetailsLabelStorageSize: `Storage size: `,
        computeDetailsLabelClientIPAddress: `Client IP address: `,
        computeDetailsLabelSSHKeyName: `SSH key name: `,
        computeDetailsLabelUsername: `User name: `,
        computeDetailsLabelPassword: `Password: `,
        computeDetailsLabelIAMRole: `IAM role: `,
        computeDetailsLabelLicenseManager: `License manager: `,
        computeDetailsLabelAdditionalSecurityGroup: `Additional security group: `,
        computeDetailsLabelAccessIP: `IP access: `,
        computeDetailsLabelAccessDescription: `Description: `,
        computeDetailsLabelAccessProtocol: `Protocol: `,
        computeDetailsLabelAccessFromPort: `Port: `,
        computeDetailsLabelAccessFromPortRange: `Ports: `,
        computeDetailsLabelAccessAddButtonLabel: `Add access`,
        computeDetailsLabelMachineID: `ID: `,
        computeDetailsLabelMachineDescription: `Description: `,
        computeDetailsLabelMachineLocation: `Cloud Location: `,
        computeDetailsLabelMachinePrivateDNS: `Private DNS: `,
        computeDetailsLabelMachinePublicDNS: `Public DNS: `,
        computeDetailsLabelMachinePrivateIP: `Private IP: `,
        computeDetailsLabelMachinePublicIP: `Public IP: `,
        computeDetailsLabelMachineState: `State: `,
        computeDetailsLabelMachineStateReason: `State reason: `,
        computeDetailsLabelMachineStatus: `Status: `,
        computeDetailsLabelMachineSystemStatus: `System status: `,
        computeDetailsLabelOutputKey: `Key: `,
        computeDetailsLabelOutputValue: `Value: `,
        computeDetailsLabelOutputDescription: `Description: `,
        computeDetailsLabelOutputExportName: `Export Name: `,
        computeDetailsUpdatedConfig: `Successfully updated configuration \${0}.`,
        computeDetaiilsNoChangesMade: `No changes needed updating.`,
        computeDetailsUpdateConfigFailed: `Unable to update configuration \${0}.`,
        computeResourceDetailPageStatusWrongForEditing: `Status is not Stopped. Unable to edit.`,
        computeResourceDetailPageNameDataUnavailable: `Name data is unavailable`,
        computeResourceDetailPageSummaryHeading: `Configuration Summary`,
        computeResourceDetailPageSummarySubHeading: `Additional Settings`,
        computeResourceDetailPageTitle: `Cloud Resource Configuration`,


        // Add Cloud Access Dialog
        addCloudAccessTitle: `Add Cloud Access`,
        addCloudAccessExplanation: `Provide values for the following fields`,
        addCloudAccessDescription: `Description: `,
        addCloudAccessIPAddress: `IP Address: `,
        addCloudAccessFromPort: `From port: `,
        addCloudAccessToPort: `To port: `,
        addCloudAccessProtocol: `Protocol: `,
        addCloudAccessAddAccessButtonLabel: `Add access`,
        addCloudAccessCancelAccessButtonLabel: `Cancel`,
        addCloudAccessSuccess: `Access successfully added.`,
        addCloudAccessDescriptionMaxLen: `50`,
        addCloudAccessInvalidIPAddress: `Invalid IP address. Please provide a valid CIDR such as 0.0.0.0/32`,
        addCloudAccessInvalidFromPort: `"From Port" range needs to be a number between 0 and 65535.`,
        addCloudAccessInvalidToPort: `"To Port" range needs to be a number between 0 and 65535.`,
        removeCloudAccessSuccess: `Access successfully removed.`,
        removeCloudAccessFailure: `Unable to remove access.  Error: \${0}.`,

        // Edit Configuration dialog
        editDialogTitle: `Edit This Configuration`,
        editDialogConfigPrompt: `Make Changes to the configuration.`,
        editDialogSaveChangesButtonLabel: `Save`,
        editDialogCancelEditButtonLabel: `Cancel`,


        // Input Prompt dialog
        inputPromptDialogActionError: `Unable to complete operation. Change your inputs and try again.`,

        // CopyToClipboardDialog
        copyToClipboardDialogTitle: `Copy To Clipboard`,
        copyToClipboardDialogExplanation: `Click "Copy" to copy to the clipboard.`,
        copyToClipboardDialogCopyButtonLabel: `Copy`,
        copyToClipboardDialogCancelButtonLabel: `Cancel`,
        copyToClipboardDialogError: `Copy password to clipboard failed.`,

        // ConnectCredentialsDialog
        connectCredentialsDialogTitle: `Access Your Cloud Resource`,
        connectCredentialsDialogNiceDCV: `To use Open MATLAB (DCV), Cloud Center will log you out of Remote Desktop (RDP).  Unsaved changes are lost.`,
        connectCredentialsDialogRDP: `To use Remote Desktop (RDP), Cloud Center will log you out of the Open MATLAB (DCV) tab. Unsaved changes are lost.`,
        connectCredentialsDialogDCV: `To use Nice DCV, Cloud Center will log you out of the Open MATLAB (RDP) tab. Unsaved changes are lost.`,

        connectCredentialsDialogGenericExplanation: `Cloud Center will open your cloud resource in a new tab.`,
        connectCredentialsDialogNiceDCVExplanation: `Cloud Center will open your cloud resource in a new tab using <strong>DCV</strong> software.`,
        connectCredentialsDialogLicenseManagerExplanation: `Cloud Center will open the Network License Manager in a new tab.`,
        connectCredentialsDialogProductionServerDashboardExplanation: `Cloud Center will open the MATLAB Production Server Admin Dashboard in a new tab.`,
        connectCredentialsDialogWebAppServerDashboardExplanation: `Cloud Center will open the MATLAB Web App Server Admin Dashboard in a new tab.`,
        connectCredentialsDialogLicenseManagerInstruction: `Enter the following user name and password in the new tab. Click <strong>\${0}</strong> to automatically open the <strong>DCV</strong> software and copy the password to the clipboard.`,
        connectCredentialsDialogExplanationRDP: `<p>Cloud Center will download a <strong>Remote Desktop</strong> file to help you access your cloud resource.</p>
    <p>Open this file in the Remote Desktop client installed on your workstation.</p>
    <p>Re-download this file if you restart the cloud resource as the IP address may change.</p>
    <p>Enter the following user name and password when prompted.</p>`,
        connectCredentialsDialogExplanationDCV: `<p>Cloud Center will download a <strong>Nice DCV</strong> file to help you access your cloud resource.</p>
    <p>Open this file in the Nice DCV client installed on your workstation.</p>
    <p>Re-download this file if you restart the cloud resource as the IP address may change.</p>
    <p>Enter the following user name and password when prompted.</p>`,
        connectCredentialsDialogGenericExplanationLine2: `If prompted, enter the following user name and password. Click <strong>\${0}</strong> to automatically open the browser tab and copy the password to the clipboard.`,
        connectCredentialsDialogFooterLabel: `Note:`,
        connectCredentialsDialogFooterDCV: `When you open <strong>DCV</strong>, you may see a warning about the certificate securing the connection.  Click Advanced and then click Continue to connect.`,
        connectCredentialsDialogFooterRDP: `When you open the <strong>Remote Desktop</strong> file, you may see a warning about the publisher of the remote connection.`,
        connectCredentialsDialogExplanation: `
    Enter the following user name and password.
    Click <strong>\${0}</strong> to automatically open the <strong>DCV</strong> software and copy the password to the clipboard.`,
        connectCredentialsDialogUsernameLabel: `User name: `,
        connectCredentialsDialogPasswordLabel: `Password: `,
        connectCredentialsDialogCopyButtonLabel: `Copy and Connect`,
        connectCredentialsDialogCopyAndContinueButtonLabel: `Copy and Continue`,
        connectCredentialsDialogCancelButtonLabel: `Cancel`,
        // Custom Access labels
        custom_access_web_app_admin_console_footer: `Copy the Keycloak Console password to the clipboard then paste into the next dialog`,
        custom_access_web_app_admin_console_explanation: `Copy Keycloak Console Password`,
        custom_access_web_app_admin_console_copyButtonLabel: `Copy Password To Clipboard`,

        // credentials page
        credentialPageErrorDeleting: `Unable to delete the credential.`,
        credentialPageErrorUnsharing: `Unable to decline the shared credential.`,
        credentialsPageUnableToDelete: `Unable to delete credential. Cloud account in use.
    Delete all cloud resources using this cloud account: \${0}`,
        credentialsPageNoCredentialsFound: `To get started, click Authorize.`,
        emptyCredentialTableMessage: `No authorized cloud accounts found.`,
        credentialsPageCredentialsResourcesLabel: `Accounts`,
        credentialsPageDefaultCredentialsResourcesMsg: `There are currently no authorized cloud accounts`, //gs: Used?
        credentialsPageCreateCredentialButtonText: `Authorize`,
        credentialsPageCreateAzureCredentialText: `Microsoft Azure`,
        credentialsPageCreateAzureRefreshTokenCredentialText: `Link account`,
        credentialsPageCredTypeHeading: `Authorize Cloud Center to manage cloud resources in your account`,
        credentialsPageCreateAWSCredentialButtonText: `<p>Amazon Web Services (AWS): Link a role.</p>`,
        credentialsPageCreateAzureComingSoonText: `<p>Microsoft Azure: Coming soon!</p>`,
        credentialsPageCreateAWSRoleCredentialText: `Provide access keys...`,
        credentialsPageImportAWSRoleCredentialText: `Follow guided steps...`,
        credentialsPageAWSCredentialType: `workflow`,
        credentialsPageAzureCredentialType: `azure`,
        credentialsPagePlatformAmazon: `Amazon`,
        credentialsPagePlatformMicrosoft: `Microsoft`,
        credentialsPageTableAccount: `Account ID`,
        credentialsPageTableDescription: `Description`,
        credentialsPageTablePlatform: `Provider`,
        credentialsPageTableCredentialType: `Access Type Required`,
        credentialsPageAWSAccountName: `AWS Account`,
        credentialsPageAddCredentialDialogTitle: `Authorize Cloud Account`,
        credentialsPageAddButtonLabel: `Add`,
        credentialsPageCloseButtonLabel: `Close`,
        credentialsPageMenuEditLabel: `Edit Description`,
        credentialsPageMenuDeleteLabel: `Remove Cloud Center's Access`,
        credentialsPageMenuUnshareLabel: `Decline Shared Cloud Center's Access`,
        credentialsPageMoreOptionTitle: `More Options`,
        credentialsPageEditDialogTitle: `Edit Description`,
        credentialsPageEditDialogExplanation: `Update the description of the cloud account.`,
        credentialsPageEditDialogConfirmButtonLabel: `Update`,
        credentialsPageEditDialogCancelButtonLabel: `Cancel`,
        credentialsPageEditDialogDescriptionInput: `Description`,
        credentialsPageEditUpdated: `Account description updated.`,
        credentialsPageReservedOwnerAction: `Only owners can delete or modify cloud account.`,

        credentialsPageDeleteCredentialDialogTitle: `Remove Cloud Center's Access`,
        credentialsPageDeleteCredentialDialogText: ` `,
        credentialsPageDeleteCredentialDialogPrompt: `Are you sure you want to remove Cloud Center's access to this cloud account?`,

        credentialsPageUnshareCredentialDialogTitle: `Decline Shared Cloud Center's Access`,
        credentialsPageUnshareCredentialDialogText: ` `,
        credentialsPageUnshareCredentialDialogPrompt: `Are you sure you want to decline sharing this cloud account?`,

        credentialsPageErrorLoadingCredentials: `Error loading user credentials for cloud account: \${0}. Try again later.`,
        credentialsPageErrorGettingOAuthURL: `Error getting OAuth URL: \${0}. Try again later.`,
        credentialsPageErrorNoCredentialTypeSelected: `Select the type of access to authorize.`,
        credentialsPageErrorNotImplemented: `Not Implemented.`,
        credentialsPageErrorCannotFindCredentialTypeID: `Unable to find the correct credential type ID.`,
        credentialsPageErrorUpdateFailed: `Unable to update description: \${0}. Try again later.`,
        // Credential Page Popovers
        credPopoverAwsArn: `Amazon Resource Name (ARN):`,
        credPopoverAzureAccount: `Account:`,
        credPopoverAzureSubscriptions: `Supported Subscriptions (requires owner access):`,
        credPopoverAzureManageSubscriptions: `Manage Azure Subscriptions`,
        credPopoverAwsAccount: `Credential:`,
        credPopoverAzureNoSubscriptions: `No subscriptions found.`,
        credPopoverAzureAddSubscription: `Please add a subscription to this account.`,
        credPopoverErrorMessage: `An error occurred. No data available.`,
        credPopoverAwsArnDetails: `ARN Details (console log in required)`,

        //AWS Import Role Page
        AWSImportRole_defaultPolicyList: `AdministratorAccess`,
        AWSImportRole_defaultRoleName: `MathWorks_CloudCenter`,
        AWSImportRole_addCredsTitle: `Manually Create and Import Amazon Web Services (AWS) Cloud Account`,
        AWSImportRole_addCredsPurpose: `Follow these steps to grant Cloud Center access to your AWS account
    so Cloud Center can create and manage cloud resources on your behalf.`,
        AWSImportRole_iamText: `Identity and Access Management`,
        AWSImportRole_step1: `Step A:`,
        AWSImportRole_step1Text: `Log in to your Amazon Web Services (AWS) console, then return to this page.`,
        AWSImportRole_step1ButtonLabel: `Log in to console`,
        AWSImportRole_step2: `Step B:`,
        AWSImportRole_step2Text1: `Complete the AWS create role wizard. `,
        AWSImportRole_step2Text2: ``,
        AWSImportRole_step2ButtonLabel: `Create role in AWS`,

        AWSImportRole_infoText: ``,

        aws_iam_policy_AdministratorAccess: `AdministratorAccess`,
        AWSImportRole_step3: `1) `,
        AWSImportRole_step3Text1: `Click <strong>AWS account</strong> under <strong>Select trusted entity</strong> > <strong>Trusted entity type</strong>.`,
        AWSImportRole_step4: ``,
        AWSImportRole_step4Text: `Click <strong>Another AWS account</strong>. Copy the following MathWorks Account ID and paste it in the <strong>Account ID</strong> box in the AWS console.`,
        AWSImportRole_step5: ` `,
        AWSImportRole_step5Text: `Select <strong>Require external ID</strong>. Copy the following External ID and paste it in the <strong>External ID</strong> box in the AWS console. Do not check the <strong>Require MFA</strong> option, click <strong>Next</strong>.`,
        AWSImportRole_step6: `2) `,
        AWSImportRole_step6Text: `Search for the following policy under <strong>Add permissions</strong> > <strong>Permissions policies</strong>. Select the check box corresponding to the policy listed below, and then click <strong>Next</strong>.`,
        AWSImportRole_step6bText: ``,
        AWSImportRole_step7: ``,
        AWSImportRole_step7Text: ``,
        AWSImportRole_step8: `3) `,
        AWSImportRole_step8Text: `Enter a <strong>Role name</strong> under the <strong>Name, review, and create</strong> > <strong>Role details</strong>. For example, MathWorks_CloudCenter. Click <strong>Create role.</strong>`,
        AWSImportRole_step9: `4) `,
        AWSImportRole_step9Text: `Click the <strong>View role</strong> button in the success notification.  Copy the <strong>ARN</strong> under <strong>Summary</strong>.`,
        AWSImportRole_step9bText: `If you closed the success notification, search for your role name and click the role name to copy the ARN.`,
        AWSImportRole_step9ButtonLabel: `Save role ARN`,
        AWSImportRole_step10: `Step C: `,
        AWSImportRole_step10Text: `Paste the role <strong>ARN</strong> from Step B.`,
        AWSImportRole_step11: ``,
        AWSImportRole_step11Text: `(Optional)`,
        AWSImportRole_addCredsFooterNote: `For more information, see `,
        AWSImportRole_awsIamText: `AWS Identity and Access Management (IAM)`,
        AWSImportRole_ccUserGuide: `Cloud Center User's Guide.`,
        AWSImportRole_nextBtnText: `Next`,
        AWSImportRole_roleArn: `Role ARN *`,
        AWSImportRole_desp: `Description`,
        AWSImportRole_acctId: `MathWorks Account ID`,
        AWSImportRole_extId: `External ID`,
        AWSImportRole_actualExternalID: `Actual External ID *`,
        AWSImportRole_copyButtonTitle: `Copy`,
        AWSImportRole_cancelBtnText: `Close`,
        AWSImportRole_errorImportingRole: `Error importing role: \${0}. Try again later.`,
        // AWS Import Role Warning Dialogs
        AWSImportRole_warningDialog_step1Title: `Log In To Console`,
        AWSImportRole_warningDialog_step1Text: `<p>Use an account that can create an AWS role.</p>
<p><ul><li>You may have a username and password to log directly in to the AWS console.</li><li>You may need to follow a single sign on link.</li></ul></p>
<p>If you cannot log in to the AWS console, contact your local support.</p>`,
        AWSImportRole_warningDialog_step1ActionButtonLabel: `Navigate to AWS`,
        AWSImportRole_warningDialog_step1CloudButtonLabel: `Cancel`,
        AWSImportRole_warningDialog_step1ProceedPrompt: ``,

        AWSImportRole_warningDialog_step2Title: `Create Role`,
        AWSImportRole_warningDialog_step2Text: `<p>Drag the Cloud Center instructions and the Amazon create role wizard side-by-side so you can refer to the instructions as you complete the wizard.</p>`,
        AWSImportRole_warningDialog_step2ActionButtonLabel: `Navigate to AWS`,
        AWSImportRole_warningDialog_step2CloudButtonLabel: `Cancel`,
        AWSImportRole_warningDialog_step2ProceedPrompt: ``,

        AWSImportRole_warningDialog_step3Title: `Copy Role ARN`,
        AWSImportRole_warningDialog_step3Text: `<p>Cloud Center opens the role summary using your default name in Step 2.</p>
<p>Copy the role ARN from the summary.</p>`,
        AWSImportRole_warningDialog_step3ActionButtonLabel: `Navigate to AWS`,
        AWSImportRole_warningDialog_step3CloudButtonLabel: `Cancel`,
        AWSImportRole_warningDialog_step3ProceedPrompt: ``,

        // These error codes are sent by the backend
        cloudcenter_aws_iam_error_inputrolepermissiondoesnotmatchccrequiredpermission: `Cloud Center requires the following 'Permissions policies' in the role: \${0}`,
        core_aws_iam_error_accessdenied: `Cloud Center cannot access the role. Verify the role ARN in Step C and that the role uses the correct MathWorks Account ID and External ID from Step B. Amazon AWS can take up to 5 minutes to activate a role`,
        core_aws_iam_error_expiredtoken: `Expired Access Key ID and Secret Access Key.`,
        core_aws_iam_error_invalidclienttokenid: `Invalid Access Key ID, Secret Access Key, or Session Token.`,

        //AWS Create Role Page
        AWSCreateRole_addCredsTitle: `<p>Authorize Cloud Center to access your cloud account.</p><p>Cloud Center creates an AWS IAM role ARN using your AWS access keys.</p>`,
        AWSCreateRole_requiredPermissions: `Cloud Center requires the following permissions:`,
        AWSCreateRole_awsAccessKey: `Access Key ID`,
        AWSCreateRole_awsSecretAccessKey: `Secret Access Key`,
        AWSCreateRole_awsSessionToken: `Session Token`,
        AWSCreateRole_awsSessionTokenOptional: `Session Token is required <strong>only</strong> when generating a temporary <a href='https://docs.aws.amazon.com/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys' target='_blank'>Access Key ID</a> via single sign-on (SSO).`,
        AWSCreateRole_awsIAMRequirement1: `Your AWS Access Key ID must be affiliated with an account that has
    permission to create a Role and assign policies in the AWS `,
        AWSCreateRole_awsIAMRequirement2: `Identity and Access Management`,
        AWSCreateRole_awsIAMRequirement3: `console.`,
        AWSCreateRole_awsIAMRequirement4: `If you do not have access to the AWS IAM console,
    contact your administrator.`,
        AWSCreateRole_storageNote: `Cloud Center does <u>not</u> store the AWS Access Key ID, Secret Access Key, and Session Token that you enter here.
    Instead, Cloud Center makes use of that information to create an AWS IAM Role in your account that grants Could Center's AWS account
    access to your AWS account, limited to the permissions you are approving.  When creating the Role, Cloud Center generates a
    shared secret (External ID) that is linked to the Role.  Cloud Center stores reference to this Role and the shared secret.`,
        AWSCreateRole_storageNoteBLOT: `If you follow these steps, only people and programs who
    have access to the Cloud Center AWS account and provide a shared secret to AWS,
    will have access to your AWS account.`,
        AWSCreateRole_singleSignOnNote1: `If you receive access to AWS through on of AWS's OpenID Connect (OIDC) or`,
        AWSCreateRole_singleSignOnNote2: `IAM SAML 2.0`,
        AWSCreateRole_singleSignOnNote3: `(For example: Google G Suite, IBM, Identacor, Matrix42, Microsoft AD FS, miniOrange, Okta, OneLogin, or Ping Identity)`,
        AWSCreateRole_singleSignOnNote4: `single sign-on (SSO) integrations, you likely need to log in to that service to generate a
    temporary AWS Access Key ID, Secret Access Key, and Session Token.`,
        AWSCreateRole_singleSignOnNote5: `Create your Access Key ID with permissions to create and link a role in AWS IAM.`,
        AWSCreateRole_nextBtnText: `Next`,
        AWSCreateRole_cancelBtnText: `Close`,

        AWSCreateRole_invalidTokens: `Invalid Access Key ID, Secret Access Key, or Session Token.`,
        AWSCreateRole_expiredTokens: `Token expired.`,
        AWSCreateRole_errorCreatingRole: `\${0}`, //Unable to create role: doesn't show up when just access key / secret access key is invalid


        // dao
        actionAborted: `Action aborted.`,

        // APS Client
        apsInvalidToken: `Invalid apsToken value returned by AuthNZ`,
        apsInvalidResponse: `Invalid respone received from ApsAuthNZ server`,
        apsActionPermanentlyDeletedFrom: `\${0} permanently deleted from \${1}.`,
        apsActionDeletedFrom: `\${0} deleted from \${1}.`,
        apsActionMovedTo: `\${0} moved to \${1}.`,
        apsActionUploadedTo: `\${0} uploaded to \${1}.`,
        apsActionRestoredTo: `\${0} restored to \${1}.`,
        apsActionCreatedIn: `\${0} created in \${1}.`,
        apsActionRenamedTo: `\${0} renamed to \${1}.`,
        apsActionInvitationChangedOn: `\${0} moved to \${1}.`,
        apsActionCopiedTo: `\${0} copied to \${1}.`,

        previewBannerText: `PREVIEW`,

        // Embedded widget tester
        widgetTestAppPageTitle: `Embedded Widget Test Page`,
        widgetTestAppChooseLabel: `Choose widget`,
        widgetTestAppUseMatlabStyle: `Use MATLAB Desktop styling`,
        widgetTestAppOverrideCss: `Override CSS with custom stylesheet`,
        widgetTestAppCssStylesheetToUse: `CSS Stylesheet URL to use:`,
        widgetTestAppIgnoreResizeMsg: `Ignore widget window resize messages`,
        widgetTestAppIframeWidthPx: `Iframe width in pixels: `,
        widgetTestAppIframeHeightPx: `Iframe height in pixels: `,
        widgetTestAppRunWidgetButton: `Run selected widget`,
        widgetTestAppResetButton: `Reset`,
        widgetTestAppSelectedWidgetContents: `Selected Widget Test Contents`,
        widgetTestAppLoggedInIntro: `You currently have valid login credentials. The embedded widgets will use those credentials to get their data.`,
        widgetTestAppNotLoggedInIntro: `You currently are not logged in and do not have valid credentials.`,
        widgetTestAppWidgetsWillNotWork: `The embedded widgets will not work without valid login credentials.`,
        widgetTestAppNeedLogin: `To obtain valid sign in credentials, first sign in to this same domain.`,
        // Embedded widget testers
        widgetTestOptionNoOptions: `No Options`,
        widgetTestOptionActions: `Actions`,
        widgetTestOptionWidgetOptions: `Widget Options`,
        widgetTestOptionStartButton: `Start Widget`,
        widgetTestOptionPleaseWait: `Loading...`,
        widgetTestOptionResetWidgetButton: `Reset widget options`,
        widgetTestOptionClearButton: `Clear`,
        widgetTestOptionChooseButton: `Choose`,
        widgetTestOptionDesiredHeightInPixels: `Desired height in pixels: `,
        widgetTestOptionDesiredWidthInPixels: `Desired width in pixels: `,

        // static example widget
        widgetTestAppExampleStaticTextWidgetTitle: `Example static widget`,
        widgetTestOptionStaticExampleTitle: `Eample Static Widget`,
        // exampleStaticTextViewController
        exampleStaticTextViewControllerStaticExampleText: `This is an example of a static widget.`,

        // details cloud resource widget
        widgetTestAppResourceDetailsTextWidgetTitle: `Cloud resource details widget`,
        widgetTestOptionStaticResourceDetailsTitle: `Cloud Resource Details Widget`,
        // resourceDetailsTextViewController
        resourceDetailsTextViewControllerDetailsResourceText: `This is the cloud resource details widget.`,

        // test Strings
        errorMsgWithNoArguments: `No permission to do operation1.`,
        errorMsgWithTwoArguments: `Unable to perform operation2 on \${0} items: \${1}`,

        // Create MATLAB Wizard
        cmlWizardExpectedValueMissing: `Select a value for \${0}, or choose a different account or cloud location.`,
        cmlWizardStep1MissingValuesWarning: `To proceed, provide required values (view configuration).`,
        cmlWizardWizardTitle: `Create Cloud Resource`,
        cmlWizardExplanation1: ``,
        cmlWizardExplanation2: ``,
        cmlWizardStep1Title: `Configuration`,
        cmlWizardStep1Legend: `Edit Configuration`,
        cmlWizardNameLabel: `Name`,
        cmlWizardNameFinePrint: `Give your cloud resource a name.`,
        cmlWizardNameMaxLength: `50`,
        cmlWizardReleaseLabel: `Release`,
        cmlWizardReleaseFinePrint: `Choose a release.`,
        cmlWizardReleasePrompt: `Select a release`,
        cmlWizardNextButtonText: `Next`,
        cmlWizardReviewButtonText: `Review`,
        cmlWizardCancelButtonText: `Cancel`,
        cmlWizardCancelEditButtonText: `Cancel`,
        cmlWizardCancelEditButtonTooltip: `Cancel and discard configuration`,
        cmlWizardSaveButtonText: `Save`,
        cmlWizardSaveButtonTooltip: `Save configuration without starting cloud resource`,
        cmlWizardLauchButtonText: `Start`,
        cmlWizardLaunchButtonTooltip: `Save configuration and start cloud resource`,
        cmlWizardStep1SelectCloudPlatformLabel: `Provider`,
        cmlWizardStep1SelectCredentialLabel: `Account`,
        cmlWizardStep1CredentialMethodCreateNew: `Create`,
        cmlWizardStep1SelectOSLabel: `Operating System`,
        cmlWizardStep1RegionSelectionLabel: `Cloud Location`,
        cmlWizardStep1ConfigSummaryTitle: `Feature Details`,
        cmlWizardStep1ConfigMatlabConfigTitle: `Configuration`,
        cmlWizardStep1ConfigCreatedConfigIdLabel: `Created Configuration ID: `,
        cmlWizardStep1ConfigAuthenticatedAsLabel: `Account: `,
        cmlWizardStep1ConfigProductLabel: `Product: `,
        cmlWizardStep1ConfigProductNicknameLabel: `Product Nickname: `,
        cmlWizardStep1ConfigReleaseLabel: `Release: `,
        cmlWizardStep1ConfigCloudPlatformLabel: `Provider: `,
        cmlWizardStep1ConfigOsLabel: `Operating System: `,
        cmlWizardStep1ConfigRegionLabel: `Cloud Location: `,
        cmlWizardStep1ConfigLostFeaturesText: `Your Step 1 configuration does not include these features:`,
        cmlWizardStep1ConfigNameLabel: `Name`,
        cmlWizardStep2Title: `Configuration`,
        cmlWizardStep2Legend: `Step 2: Additional Settings`,
        cmlWizardViewConfigButtonLabel: `Configuration`,
        cmlWizardCompleteTitle: `\${0} \${1} \${2}`,
        cmlWizardSummaryDescription: `Your cloud resource has been created.`,
        cmlWizardSummaryAutoCompleteDescription: `Create Cloud Resource`,
        cmlWizardSummaryLostFeatures: `These features will be unavailable: `,
        cmlWizardSummarySubTitle: `Configuration Summary`,
        cmlWizardSummaryEditLinkText: `Edit`,
        cmlWizardSummaryErrorCloud: `The "\${0}" field is invalid because of "\${1}"\${2}. Edit your AWS Configuration to correct it.`,
        cmlWizardStep2ErrorCloud: `"\${1}" is invalid\${2}`,
        cmlWizardSummaryErrorInvalidValues: `Invalid Values`,
        cmlWizardSummaryErrorValidValues: `Valid Values`,
        cmlWizardSummaryErrorDetails: `Details`,
        cmlWizardCloseSummaryButtonText: `Close`,
        cmlWizardGoToDetailsButtonText: `Details`,
        cmlWizardStep2InvalidValues: `There are invalid fields. Correct them before proceeding.`,
        cmlWizardStep2InvalidItem: `Invalid value`,
        cmlWizardStep2OptionalFieldWithNoData: ` ...create relevant items to enable selection ...`,
        cmlWizardChooseDescriptionLabel: `Description`,
        cmlWizardChooseDescriptionText: `Choose a description`,
        cmlWizardSummaryAttributeCloudChoice: `\${0} (\${1})`,
        cmlWizardSummaryAttributeLabel_mlName: `Name: `,
        cmlWizardSummaryAttributeLabel_mlNameInput: `Name: `,
        cmlWizardSummaryAttributeLabel_cloudPlatform: `Provider: `,
        cmlWizardSummaryAttributeLabel_credentialSelector: `Account: `,
        cmlWizardSummaryAttributeLabel_cloudLocationSelector: `Cloud Location: `,
        cmlWizardSummaryAttributeLabel_cloudChoice: `Provider: `,
        cmlWizardSummaryAttributeLabel_osName: `Operating System: `,
        cmlWizardSummaryAttributeLabel_osChoice: `Operating System: `,
        cmlWizardSummaryAttributeLabel_releaseSelector: `Release: `,
        cmlWizardSummaryAttributeLabel_InstanceType: `Instance Type: `,
        cmlWizardSummaryAttributeLabel_RootVolumeSize: `Storage Size: `,
        cmlWizardSummaryAttributeLabel_InstanceName: `Instance Name: `,
        cmlWizardSummaryAdditionalAttributes: `AWS Machine Configuration: `,
        cmlWizardSummaryInvalidValues: `There are invalid fields. Correct them before proceeding: \${0}`,
        cmlWizardSummaryUnableToStart: `\${0}: Unable to start. Edit your configuration and/or correct the following error to proceed: \${1}`,
        cmlWizardSummaryLaunchingResource: `Launching cloud resource \${0}...`,
        cmlWizardPreviewButtonText: `Next`,
        cmlWizardBackButtonText: `Previous`,
        cmlWizardStepErrorNotice: `Unable to complete wizard due to error in \${0}: \${1}`,
        cmlWizardSummaryStartButtonTooltip: `Save configuration and start cloud resource`,
        cmlWizardSummarySaveOnlyButtonTooltip: `Save configuration without starting cloud resource`,
        cmlWizardSummaryCancelButtonTooltip: `Cancel and discard the configuration`,
        cmlWizardPreviewSummaryError: `An error occurred on the default summary, opening the Edit page.`,
        cmlWizardMissingCreatePrerequisite: `Unable to create specified resource: \${0}.`,
        wizardCreateAWSKeyPairErrorInit: `Unable to determine Amazon AWS cloud account. Reload page and try again.`,
        wizardCreateAWSKeyPair: `Create new...`,
        wizardCreateAWSKeyPairDialogTitle: `Create New Key Pair`,
        wizardCreateAWSKeyPairDialogExplanation: `Create a new EC2 key pair to allow SSH access to your AWS instances. Cloud Center will download the private key file (*.pem file).  Store the file in a secure and accessible location.
    Note: You will not be able to download the file again after you create it. For details on creating EC2 key pairs, see <a target="_blank" href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-key-pairs.html">https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-key-pairs.htm</a>.`,
        wizardCreateAWSKeyPairConfirmButtonLabel: `Download Key`,
        wizardCreateAWSKeyPairCancelButtonLabel: `Cancel`,
        wizardCreateAWSKeyPairDescriptionInput: `Key Name`,
        wizardCreateAWSKeyPairErrorAlreadyExists: `Key Name already exists.`,
        wizardCreateAWSKeyPairErrorRequired: `Key Name required.`,
        wizardCreateAWSKeyPairErrorTooLong: `Key Name exceeds maximum length of 50.`,
        wizardCreateAWSKeyPairErrorUnexpected: `Unexpected error. Reload the page or try again later.`,
        wizardCreateAWSKeyPairDownloadError: `Failed to download the SSH key .pem file. \${0}`,


        // Wizard Warning Dialog
        wizardWarningDialogTitle: `Unsaved data`,
        wizardWarningDialogProceedPrompt: ``,
        wizardWarningDialogMainWarning: `If you continue, you lose all your settings.`,
        wizardWarningDialogCancelButtonLabel: `Cancel`,
        wizardWarningDialogProceedButtonLabel: `Continue Anyway`,

        // Cloud Center Data Service Errors
        dataServiceErrorInvalidJson: `Invalid JSON data returned from service.`,
        dataServiceErrorNoResults: `No matching rules found.`,
        dataServiceErrorMultiResults: `More than one body returned by service.`,
        dataServiceErrorServerError: `A server error occurred. Try again later.`,
        dataServiceErrorServerErrorWithInfo: `A server error occurred: \${0}. Try again later.`,
        dataServiceErrorUnableToGetData: `A server error occurred that prevented getting data that is necessary to proceed`,
        dataServiceErrorCloudCenterVaultInternal: `An unknown server error occurred. Retry your last save or refresh the page.`,
        dataServiceInfoLongerThanExpected: `It is taking longer than expected to complete your request.  Please wait for your action to complete.  \${0}s elapsed.`,

        // Dynamic button labels
        cmlWizardDynamicButtonAws: `Amazon Web Service`,
        cmlWizardDynamicButtonAzure: `Microsoft Azure`,
        cmlWizardDynamicButtonWindows: `Microsoft Windows`,
        cmlWizardDynamicButtonLinux: `Linux`,

        // warning dialogs
        warningTitleNeedCredential: `Welcome to Cloud Center`,
        warningTextNeedCredential: `<p>From Cloud Center, you can create and manage cloud resources in your account at Amazon Web Services and Microsoft Azure, including:</p>
<p>
<ul>
<li>A single machine with MATLAB&reg; installed that you can access from a web browser or a remote desktop application.</li>
<li>A MATLAB Parallel Server&trade; cluster that you can access from any MATLAB.</li>
</ul>
</p.
<p>Don't have a cloud account? Get immediate access to MATLAB in a web browser using <a href="https://matlab.mathworks.com">MATLAB Online</a>.</p>`,
        warningActionLabelNeedCredential: `Get Started`,
        warningCloseLabel: `Close`,

        // Edit form
        editFormTitle: `Configuration`,
        editFormSaveLink: `Save`,
        editFormStartLink: `Start`,
        editFormCancelLink: `Cancel`,
        editFormLabelName: `Name`,
        editFormLabelRelease: `Release`,
        editFormLabelCloudPlatform: `Provider`,
        editFormLabelCredId: `Account`,
        editFormLabelOS: `Operating System`,
        editFormLabelRegion: `Cloud Location`,

        // Notification manager
        successNotificationTitle: `Success`,
        warningNotificationTitle: `Warning`,
        errorNotificationTitle: `Error`,
        infoNotificationTitle: `Info`,
        notificationShowAll: `Show`,
        notificationHideAll: `Hide`,
        notificationToggleCollapse: `Toggle the collapse of notification content`,

        // LicenseManager Component
        licenseManagerNetworkRadioLabel: `Network License Manager`,
        licenseManagerOnlineRadioLabel: `Online Licensing`,

        // ClusterStateManager Component
        clusterStateManagerPersonalLabel: `Personal Cluster`,
        clusterStateManagerShareableLabel: `Shareable Cluster`,
        clusterStateManagerCheckboxText: `Set cluster firewall rules to let MATLAB communicate with your cluster.`,

        // NetworkAccess Component
        networkAccessRunInSameNetworkRadioLabel: `Run in same network as: `,
        networkAccessSpecifyManuallyRadioLabel: `Manually specify network information: `,

        // Custom Elements General
        cloudCenterElementCreateNew: `Create new...`,

        // CreateNewSSLCertificateDialog
        createNewSSLCertificateTitle: `Create New SSL Certificate`,
        createNewSSLCertificateCancel: `Cancel`,
        createNewSSLCertificateCreate: `Create`,
        createNewSSLCertificateBody: `Create a new SSL Certificate to allow SSH access to your AWS instances.
    Cloud Center will download the private key file (*.pem file).
    Store the file in a secure and accessible location.
    Note: You will not be able to download the file again after you create it.
    For details on creating SSL Certificates, see `,
        createNewSSLCertificateHelpURL: `https://docs.aws.amazon.com/acm/latest/userguide/import-certificate.html`,
        createNewSSLCertificateOrganizationInputLabel: `Organization Name`,
        createNewSSLCertificateCodeSelectLabel: `Country`,
        createNewSSLCertificateInvalidOrgName: `Invalid organization name`,
        createNewSSLCertificateMissingCode: `Please pick a country`,

        // EditableText Component
        editableTextSaveButtonTitle: `Update the name`,
        editableTextCancelButtonTitle: `Cancel edit`,
        editableTextEditButtonTitle: `Edit the value`,
        editableTextValidationError: `The input value is not valid`,
        credentialDescriptionEditButtonTitle: `Edit account description`,
        resourceNameEditButtonTitle: `Edit cloud resource name`,

        // MWClusterWorkerPicker Component
        workerPickerFractionalMachineWarning: `The current Workers In Cluster (Max) value requires an additional fractional machine.`,
        workerPickerInitialNumWorkersInClusterPopText: `Number of workers to start`,
        workerPickerInitialNumWorkersInClusterLabel: `Initial Count`,
        workerPickerMaxNumWorkersInClusterUpperLimit: `Upper Limit`,
        workerPickerMaxWorkersInClusterNote: `(Max: 1024)`,
        workerPickerWorkersInClusterFieldsetLabel: `Workers In Cluster`,
        workerPickerMachinesInClusterFieldsetLabel: `Machines In Cluster`,
        workerPickerAfterNote: `Note:  You are charged for the use of your cloud provider's clusters.  Consider periodically checking your active resources through your cloud provider.`,
        workerPickerMachinesInClusterLabel: `Machines In Cluster`,
        workerPickerMachinesInClusterNote: `(Excluding Headnode)`,
        workerPickerUseArrowKeys: `Use arrow keys: `,
        workerPickerNumKeydownError: `Use the up and down arrows to set the value`,


        monthAndYear: `\${0} of \${1}`,
        // months
        month_01: "January",
        month_02: "February",
        month_03: "March",
        month_04: "April",
        month_05: "May",
        month_06: "June",
        month_07: "July",
        month_08: "August",
        month_09: "September",
        month_10: "October",
        month_11: "November",
        month_12: "December",

        // MachineTypeChooserWithFinder Component
        machineTypeFinderSummaryTitle: `Machine and Network Choices`,

        machineTypeFinderLoading: `Loading...`,
        machineTypeFinderReloadPageWithNewInformation: `You have selected a cloud resource in a different location (\${0}) and with a different cloud account (\${1}).

Reload the page with those values?

Reloading will require you to confirm the VPC, subnet, and machine type.

If you do not reload the page, choose a different existing resource, or use a different machine type finder option.`,
        machineTypeFinderReloadPageWithNewInformationLocationOnly: `You have selected a cloud resource in a different location (\${0}).

Reload the page with those values?

Reloading will require you to confirm the VPC, subnet, and machine type.

If you do not reload the page, choose a different existing resource, or use a different machine type finder option.`,
        machineTypeFinderReloadPageWithNewInformationAccountOnly: `You have selected a cloud resource in a different cloud account (\${0}).

Reload the page with those values?

Reloading will require you to confirm the VPC, subnet, and machine type.

If you do not reload the page, choose a different existing resource, or use a different machine type finder option.`,
        machineTypeFinderNoSupportedInstancesForTheSelectedExistingResource: `There are no supported instances for this product based on the cloud account and location (\${0} \${1} \${2} \${3}) of the exising resource you selected.

Choose a different existing resource, or use a different machine type finder option.`,
        machineTypeFinderNoSupportedInstancesForTheSelectedLocation: `There are no supported instances for this product based on the location (\${0} \${1} \${2}) you selected.

Choose a different region, network or subnet, or use a different machine type finder option.`,
        machineTypeFinderNoSupportedSubnetsForTheSelectedVPC: `There are no subnets for this product based on the location (\${0} \${1}) you selected.

Choose a different region or network, or use a different machine type finder option.`,
        machineTypeFinderNoPublicSubnetsForTheSelectedVPC: `There are no public subnets for this product based on the location (\${0} \${1}) you selected.

Choose a different region or network, or use a different machine type finder option.`,
        machineTypeAvailabilityListForSelectedMachineTypeNoMachineTypeSelected: `No machine type selected.  Choose a machinemachineTypeFinderReloadPageWithNewInformationLocationOnly type, or use a different machine type finder option.`,
        machineTypeAvailabilityListForSelectedMachineTypeDifferentRegionPicked: `You have selected a network in a different location (\${0}).

Reload the page with those values?

If you do not reload the page, choose a different network, machine type or cloud account, or use a different machine type finder option.`,

        machineTypeAvailabilityListForSelectedMachineTypeNoLocationFound: `There are no locations in your account with availability for: \${0}.

Choose a different machine type or account, or use a different machine type finder option.`,
        machineTypeFinderNoSupportedInstancesWithThisCombination: `No supported instances with this combination`,
        machineTypeFinderCurrentSelection: `Currently Selected`,
        machineTypeFinderByMachineType: `Choose by Instance Type`,
        machineTypeFinderByExistingResource: `Choose based on Network of Existing Resource`,
        machineTypeFinderByLocation: `Choose by Network`,
        machineTypeFinderByExistingResourceErrorResourceInDifferentRegion: `The resource you selected is in a different region. Switch to that region?`,
        machineTypeFinderByExistingResourcePopText: `Select an existing resource to use its network information.
    You will be limited in your choice of machine types by those available in that network,
    although there may still be variability of instance types based on the subnet you select.`,
        cloudCenterElementFindMachineType: "Find in account...",
        machineTypeFinderTitle: `Search Machine Type In Your Cloud Account`,
        machineTypeFinderCancel: `Cancel`,
        machineTypeFinderSearch: `Search`,
        machineTypeFinderApply: `Apply`,
        machineTypeFinderCancelEdit: `Cancel editing choices`,
        machineTypeFinderEdit: `Edit Choices`,
        machineTypeFinderEditChoicesTooltip: `Edit the machine and network choices`,
        machineTypeFinderResource: `Resource`,
        machineTypeFinderMachine: `Machine`,
        machineTypeFinderLocation: "Network",
        machineTypeFinderApplyCurrentSelection: `Apply current selection`,
        machineTypeFinderInvalidMachineType: `Invalid machine type`,
        machineTypeFinderBody: `Search for the selected machine type across your account.`,
        machineTypeFinderHelpURL: `https://docs.aws.amazon.com/acm/latest/userguide/import-certificate.html`,
        machineTypeFinderMachineTypeInputLabel: `Machine Type`,
        machineTypeFinderMachineTypeNotFound: `<p>No locations were found in your account with availability for: \${0}.</p>
    <p>This usually is a result of the cloud provider not having sufficient hardware to cover the selected machine or the manager of your cloud account limiting the number of this type of machine you are allowed to start.</p>
    <p>Try a smaller machine type in the same class, a different machine type, or try again later as this hardware may become available.</p> `,
        machineTypeFinderInstructions: `Select the machine type you wish to search for and click "Search"`,
        machineTypeFinderSearching: `<p>Searching for \${0} availability.</p>`,
        machineTypeFinderNoRadioButtonSelected: `Select a machine type finder option and then choose apply.`,
        machineTypeFinderUnableToLoadDefaults: `Unable to load defaults for VPC, subnet, or machine type.

Run a machine type finder to select a VPC, subnet, and machine type.`,
        machineTypeFinderExpander: `Change Machine or Network`,


        // Server error codes (error.<code>)
        sharingURLis_sunsetted: `This release is no longer supported and cannot be copied.`,
        releaseIsDeprecated: `Support for this release will be removed in Cloud Center in \${0}. Consider choosing another release.`,
        releaseIsSunsetted: `Support for this release was removed in Cloud Center in \${0}. Choose another release`,
        deprecatedSuffix: ` (soon to be removed)`,
        deprecatedTooltip: `Support for this release will be removed in Cloud Center in \${0}.`,
        sunsettedTooltip: `Support for this release was removed in Cloud Center in \${0}.`,
        clusterDeprecateDate: `the future`

    },

    "ja-jp": false
});
