/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "util",
  "templates/createMatlabWizardSteps",
  "dialogs/wizardWarningDialog",
  "wizard/configInfoSummary",
  "wizard/createMatlabWizardStep1",
  "wizard/createMatlabWizardStep2",
  "wizard/createMatlabWizardSummary",
  "service/cachedDataService",
  "service/datatransform/uiElementUtil",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "mw-progress-indicator/ProgressIndicator",
  "constants"
], function( _, $, Backbone, Util, StepsTemplate, WizardWarningDialog,
             ConfigInfoSummary, CreateMatlabWizardStep1,
             CreateMatlabWizardStep2, CreateMATLABWizardSummary,
             CachedDataService, UIElementUtil,
             I18NStringResource, DojoString, ProgressIndicator, CCWA_Constants) {

  class CreateMATLABWizard extends Backbone.View {

    constructor (args) {
      super(args);
      this.events = {
        "click div.wizardBody div.buttonContainer > button#cancelEditButton": "onCancelEdit",
        "click div.wizardBody div.buttonContainer > button#saveConfigurationButton": "onSave",
        "click div.wizardBody div.buttonContainer > button#launchConfigButton": "onLaunch",
        "click div.wizardBody > div#step2Container div.buttonContainer > button#cancelWizardButton": "showCancelWarningDialog",
        "click div.wizardBody form a.sectionExpander": "toggleSection",
        "change div.wizardBody div#step1Wrapper input#mlNameInput": "onNameChange",
        "change div.wizardBody div#step2Wrapper select#VPC" : "onCascadeSelectChanged",
        "change div.wizardBody div#step2Wrapper select#Subnet" : "onCascadeSelectChanged",
        "change div.wizardBody div#step2Wrapper select#virtualNetworkResourceID" : "onCascadeSelectChanged",
        "change div.wizardBody div#step2Wrapper select#subnetName" : "onCascadeSelectChanged"
      };
      this.template = StepsTemplate;
      if (!args || typeof args !== 'object') {
        throw new TypeError("Invalid constructor arguments");
      }
      let dataServiceIsValid = Util.dataServiceIsValid(args.dataService);
      if (! dataServiceIsValid) {
        throw new TypeError("Invalid dataService argument");
      }
      this.dataService = args.dataService;
      if (!(this.dataService instanceof CachedDataService) && Util.clientCachingIsEnabled()) {
        this.localDataService = new CachedDataService({
          baseService: this.dataService
        });
        this.initLocalDataService();
      } else {
        this.localDataService = this.dataService;
      }
      if (! (args.product && typeof args.product === 'string')) {
        throw new TypeError("Invalid product argument");
      }
      this.productName = args.product;
      this.autoComplete = Object.prototype.hasOwnProperty.call(args, "autoComplete") ? args.autoComplete : false;
      this.previewSummary = this.autoComplete;
      this.configInfoSummary = new ConfigInfoSummary({product: args.product});
      this.rulesManager = args.rulesManager;
      this.productInfo = args.productInfo; // these are the rules IDs available to the user for this product
      this.autoCompleteItemArray = [];
      this.step1 = null;
      this.step2 = null;
      this.wizardSummary = null;
      this.step1Complete = false;
      this.step2Complete = false;
      this.errorOccurredInPreviewSummary = false;
      this.savedStep2Values = null;
      this.defaultRule = null;
      this.desiredRuleValues = null;
      this.onDependentOptionError = _.debounce(this.dependentOptionErrorHandler, 500).bind(this);

      _.bindAll(this, "onToNextStep", "showCancelWarningDialog", "onNameChange",
                "showCancelWarningDialogWithoutPropagation", "onStep1Change",
                "showCancelWarningGoToAccountsWithoutPropagation",
                "onCascadeSelectChanged", "clickAccountsLinkWhenWizardCloses",
                "onCancelEdit", "onSave", "onLaunch", "onCancelWizard",
                "showConfigSummary", "showStep1");
    }

    static  get CANCEL_WIZARD_EVENT () {
      return "cancelcreatemlwizard:ccwa";
    }

    /*
      Local caching initialization
    */
    initLocalDataService () {
      this.getLocalDataService().init(
        [
          "ui",
          "logging",
          "workflow"
        ], // components to add to localDataService
        // APIs to cache
        [
          {
            set: "ui/getRulesArrayByProduct",
            clear: null
          },
          {
            set: "ui/listSection2UIElementsByRuleId",
            clear: null
          },
          {
            set: "ui/getCredentialDescription",
            clear: null
          },
          {
            set: "ui/listCredentials",
            clear: null
          },
          {
            set: "ui/queryDependentOptionData",
            clear: null
          },
          {
            set: "workflow/details",
            clear: [
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/defaultRuleByProduct",
            clear: [
              "workflow/create",
              "workflow/update"
            ]
          },
          {
            set: "workflow/defaultRule",
            clear: [
              "workflow/create",
              "workflow/update"
            ]
          },
          {
            set: "workflow/listRulesByProduct",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/getRuleById",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          }
        ]
      );
    }

    clearCache (event) {
      this.getLocalDataService().clearAll();
    }

    /*
     * property getters
     */
    getDefaultRule () { return this.defaultRule; }
    setDefaultRule (rule) { this.defaultRule = rule; }

    getDesiredRuleValues () { return this.desiredRuleValues; }
    setDesiredRuleValues (values) {
      if (values && typeof values === 'object') {
        const step1Values = {};
        const step2Values = {};
        // Either we use RuleID (preferred), or we use the set of values that combined equals a ruleID
        // but not both.
        if (values.ruleId) {
          step1Values.ruleId = decodeURIComponent(values.ruleId);
          if (values["mw-credential-id"]) {
            step1Values["mw-credential-id"] = decodeURIComponent(values["mw-credential-id"]);
          }
          if (values.credentialTypeId) {
            step1Values.credentialTypeId = decodeURIComponent(values.credentialTypeId);
          }
          if (values.platform) {
            step1Values.platform = decodeURIComponent(values.platform);
          }
          if (values.platform === 'azure') {
            if (values["mw-subscription-id"]) {
              step1Values["mw-credential-id"] += `(${decodeURIComponent(values["mw-subscription-id"])})`;
              step1Values['mw-subscription-id'] = decodeURIComponent(values["mw-subscription-id"]);
            }
          }
        } else {
          if (values.release) {
            step1Values.release = decodeURIComponent(values.release);
          }
          if (values.provider) {
            step1Values.cloudChoice = decodeURIComponent(values.provider);
          }
          if (values.os) {
            step1Values.osChoice = decodeURIComponent(values.os);
          }
          if (values.location) {
            step1Values.cloudLocation = decodeURIComponent(values.location);
          }
          if (values.description) {
            step1Values.description = decodeURIComponent(values.description);
          }
        }
        for (const key of Object.keys(values)) {
          switch (key) {
            case "release":
            case "provider":
            case "os":
            case "description":
              continue;
            default:
              step2Values[key] = decodeURIComponent(values[key]);
          }
        }
        this.desiredRuleValues = {
          step1: step1Values,
          step2: step2Values
        };
      } else {
        this.desiredRuleValues = null;
      }
    }

    getProductName () { return this.productName; }

    isAutoCompleteOn () { return this.autoComplete; }

    setAutoComplete (autoCompleteIsOn) {
      this.autoComplete = (autoCompleteIsOn === true);
      if (this.getStep1()) {
        this.getStep1().setAutoComplete(this.autoComplete);
      }
      if (this.getStep2()) {
        this.getStep2().setAutoComplete(this.autoComplete);
      }
    }

    isPreviewSummaryOn () { return this.previewSummary; }

    setPreviewSummary (previewIsOn) { this.previewSummary = previewIsOn; }

    getDataService () { return this.dataService; }

    getLocalDataService () { return this.localDataService; }

    getConfigInfoSummary () { return this.configInfoSummary; }

    getRulesManager () { return this.rulesManager; }

    getProductInfo () { return this.productInfo; }

    getStep1 () { return this.step1; }
    setStep1 (step) { this.step1 = step; }

    getStep2 () { return this.step2; }
    setStep2 (step) { this.step2 = step; }

    getWizardSummary () { return this.wizardSummary; }
    setWizardSummary (step) { this.wizardSummary = step; }

    setHasPreviewSummaryError (errorOccurred) { this.errorOccurredInPreviewSummary = !!errorOccurred; }
    hasPreviewSummaryError () { return this.errorOccurredInPreviewSummary; }

    getAutoCompleteItems () { return this.autoCompleteItemArray; }
    setAutoCompleteItems (array) { this.autoCompleteItemArray = array; }
    toggleAutoCompleteItems () {
      this.getAutoCompleteItems().forEach(item => item.classList.toggle("autoComplete"));
    }

    getSavedStep2Values () { return this.savedStep2Values; }
    setSavedStep2Values (values) {
      Util.removeSecureFieldsFromStep2Values(values);
      this.savedStep2Values = values;
      this.setDesiredRuleValues(null);
      if (this.step1) {
        this.step1.setDesiredStepValues(null);
      }
      if (this.step2) {
        this.step2.setDesiredStepValues(null);
      }
    }

    /*
     *  Selector-based getters
    */

    getWizardTitleContainer () { return this.el.querySelector('div.wizardStepsContainer > h1.wizardTitle'); }
    getSaveConfigurationButton () { return this.el.querySelector('button#saveConfigurationButton'); }
    getStartConfigurationButton () { return this.el.querySelector('button#launchConfigButton'); }
    getMLNameInput () { return this.el.querySelector('div.wizardBody div#step1Wrapper input#mlNameInput'); }
    getStep1BusySpinnerContainer () { return this.el.querySelector('div#section1BusySpinnerContainer'); }
    getStep2BusySpinnerContainer () { return this.el.querySelector('div#section2BusySpinnerContainer'); }
    getStep1ContainerNoSpinner () { return this.el.querySelector('div#step1ContentContainer.generatedCodeContainer'); }
    getStep1SummaryBusySpinnerContainer () { return this.el.querySelector('div#section1SummaryBusySpinnerContainer'); }
    getInitialCreateButtonClickSpinner () { return document.querySelector("div#initialCreateButtonClickSpinner"); }
    getAutoCompleteSpinnerContainer () { return this.el.querySelector('div#autoCompleteSpinnerContainer'); }
    getSummaryContainer () { return this.el.querySelector('div#summaryContainer.wizardSummary'); }
    getEditButton () { return this.el.querySelector('button#editButton'); }
    getEditButtonSpinner () {
      let editButtonSpinner = null;
      const editButton = this.getEditButton();
      if (editButton) {
        editButtonSpinner = editButton.querySelector("span.progressSpinnerContainer");
      }
      return editButtonSpinner;
    }
    getStep1Container () { return this.el.querySelector('div#step1Container.wizardStepContainer'); }
    getStep1ContentContainer () { return this.el.querySelector('div#step1ContentContainer.generatedCodeContainer'); }
    getStep2Container () { return this.el.querySelector('div#step2Container.wizardStepContainer'); }
    getStep2ContentContainer () { return this.el.querySelector('div#step2ContentContainer.generatedCodeContainer'); }
    getAllStep2Wrappers () { return this.el.querySelectorAll("div#step2Wrapper.wizardStepWrapper"); }
    getStep1Wrapper () { return this.el.querySelector('div#step1Wrapper.wizardStepWrapper');}
    getStep2Wrapper () { return this.el.querySelector('div#step2Wrapper.wizardStepWrapper');}
    getAllAutoCompleteItems () { return this.el.querySelectorAll(".autoComplete"); }
    getNextButtonByID (buttonId) {
      return this.el.querySelector(`div.wizardBody > div.wizardStepContainer div.buttonContainer > button#${buttonId}`);
    }
    getConfigInfoContainer () { return this.el.querySelector('div#stepsConfigInfoContainer'); }
    getButtonsContainer () { return this.el.querySelector('div.wizardBody > div#step1Container div.buttonContainer.stepComponentContainer'); }
    getVPCSelect () {
      let el = this.el.querySelector("select#VPC");
      if (!el) {
        // look for azure's virtualNetworkResourceID
        el = this.el.querySelector("select#virtualNetworkResourceID");
      }
      return el
    }
    getPasswordInput () { return this.el.querySelector("input.password#Password"); }
    getConfirmPasswordInput () { return this.el.querySelector("input.password#ConfirmPassword"); }
    getClientIPAddressInput () { return this.el.querySelector("input#ClientIPAddress");}
    getInstanceTypeSelect () { return this.el.querySelector("div.section2InputContainer.aws_ec2_instance_type select"); }
    getFacetsContainer () { return document.querySelector('div#computeResourcePageFacetPicker > ul.facets'); }
    getLogoButton () {
      // There are actually two logo buttons: 1) in the mobile header and, 2) in the desktop header.
      // Determine which is in view and return it.
      let activeButton;
      const buttons = document.querySelectorAll('button.btn.btn-link.default-link.ComputeResourcePage');
      for (const button of buttons) {
        /* istanbul ignore next */
        if (button.offsetWidth > 0 || button.offsetHeight > 0) {
          activeButton = button;
        }
      }
      return activeButton;
    }

    /*
     *  Methods to manipulate UI elements
    */

    toggleSection (event) {
      let sectionHeader = null;
      if (event) {
        if (event.preventDefault) {
          event.preventDefault();
        }
        if (event.currentTarget) {
          Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
        }
        if (event.target) {
          sectionHeader = event.target.closest('.additionalDetailHeader')
        }
      }
      Util.toggleSection(sectionHeader);
    }

    removeAutoCompleteCSS () {
      let autoCompleteItems = this.getAllAutoCompleteItems();
      if (autoCompleteItems && autoCompleteItems.length) {
        let len = autoCompleteItems.length;
        for (let i = 0; i < len; i++) {
          let item = autoCompleteItems[i];
          item.classList.remove('autoComplete');
        }
      }
    }

    disableButtons () {
      const saveButton = this.getSaveConfigurationButton();
      const startButton = this.getStartConfigurationButton();
      if (saveButton) {
        saveButton.disabled = true;
        saveButton.classList.add('disabled');
      }
      if (startButton) {
        startButton.disabled = true;
        startButton.classList.add('disabled');
      }
    }

    enableButtons () {
      const saveButton = this.getSaveConfigurationButton();
      const startButton = this.getStartConfigurationButton();
      if (saveButton) {
        saveButton.disabled = false;
        saveButton.classList.remove('disabled');
      }
      if (startButton) {
        startButton.disabled = false;
        startButton.classList.remove('disabled');
      }
    }

    /*
     *  Event listeners
     */

   onCascadeSelectChanged (event) {
     this.disableButtons();
   }

    // When the name field in Step1 changes, inject the new value into Step2's data.
    onNameChange (event) {
      if (! this.isAutoCompleteOn()) {
        if (this.getStep2() && this.getStep2().getUIGenerator() &&
            this.getStep2().getUIGenerator().getInputSelectorMap()) {
              const selectorMap = this.getStep2().getUIGenerator().getInputSelectorMap();
              const hiddenNameSelector = selectorMap.get("mw-name");
              const hiddenNameField = this.el.querySelector(hiddenNameSelector.selector);
              const nameInput = this.getMLNameInput();
              const hiddenFieldData = this.getStep2().getHiddenFieldData();
              if (nameInput && hiddenFieldData && hiddenNameField) {
                const newNameValue = nameInput.value;
                hiddenFieldData["mw-name"] = newNameValue;
                this.getStep2().hiddenFieldData = hiddenFieldData;
                hiddenNameField.value = newNameValue;
              }
        }
      }
    }

    onStep1Change (event) {
      if (! this.isAutoCompleteOn()) {
        this.getStep1().saveStepValues();
        try {
          let step2Values = this.getStep2Values();
          if (step2Values || (!step2Values && (!this.getDesiredRuleValues() || !this.getDesiredRuleValues().step2))) {
            this.setSavedStep2Values(step2Values);
          }
        } catch (error) {
          Util.consoleLogWarning('getStep2Values', error);
        }
        this.disableButtons();
        let section2BusySpinner = this.getStep2BusySpinnerContainer();
        if (section2BusySpinner) {
          section2BusySpinner.style.display = 'flex';
        }
        this.step1Complete = false;
        this.step2Complete = false;
        this.onToNextStep();
      }
    }

    async validateInOnToNextStep () {
        if (this.isAutoCompleteOn()) {
          return
        }

        try {
          await this.validateOnlyConfiguration();
        } catch (err) {
          let firstElement;
          if (err && typeof err === "object" && err.message && typeof err.message === "string") {
            let errDetails = JSON.parse(err.message);
            for (let errorField in errDetails){
              if (errorField === "cloud") {
                let dtl = errDetails[errorField][0];
                if (!firstElement) {
                  firstElement = this.el.querySelector(`label[for='${dtl.details}'`);
                }
                let mainError = "<"+I18NStringResource.unknownValue+">";
                if (dtl.invalid_values && dtl.invalid_values.length>0) {
                  mainError = dtl.invalid_values.shift();
                }
                let subError = "";
                if (dtl.invalid_values && dtl.invalid_values.length>0) {
                  subError = " (" + dtl.invalid_values.join(", ") + ")";
                }
                let errContainer = this.el.querySelector(".inline_form_error." + dtl.details);
                let errMsgContainer = this.el.querySelector(".inline_form_error_msg." + dtl.details);

                if (errContainer && errMsgContainer) {
                  errMsgContainer.innerText = DojoString.substitute(I18NStringResource.cmlWizardStep2ErrorCloud, [dtl.details, mainError, subError]);
                  errContainer.style.display = "block";
                } else {
                  Util.notify("ERROR", DojoString.substitute(I18NStringResource.cmlWizardSummaryErrorCloud, [dtl.details, mainError, subError]));
                }
                continue;
              }
              let errMsg = I18NStringResource.cmlWizardStep2InvalidValues;
              try {
                errMsg = DojoString.substitute(I18NStringResource.cmlWizardSummaryInvalidValues, [JSON.stringify(errorField)]);
              } catch (error) {
                errMsg = error.message;
              }
              Util.notify("ERROR", errMsg);
            }
          }
          if (firstElement) {
            try {
              firstElement.scrollIntoViewIfNeeded(true);
            } catch (error){
              throw err;
            }
          }
          throw err;
        }
    }

    async onToNextStep (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      let targetButtonId;
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      const busySpinner = this.getAutoCompleteSpinnerContainer();
      if (! this.step1Complete) {
        if (this.getStep1SummaryBusySpinnerContainer()) {
          this.getStep1SummaryBusySpinnerContainer().remove();
        }
        if (this.getStep1ContainerNoSpinner()) {
          this.getStep1ContainerNoSpinner().style.display="block";
        }
        await this.renderStep2();

        if (busySpinner) {
          busySpinner.style.display = "none";
        }
        let summaryContainer = this.getSummaryContainer();
        if (summaryContainer) {
          summaryContainer.style.visibility = 'visible';
        }

        const editButton = this.getEditButton();
        if (editButton) {
          const editButtonSpinner = this.getEditButtonSpinner();
          if (editButtonSpinner) {
            editButton.removeChild(editButtonSpinner);
          }
          editButton.disabled = false;
          editButton.classList.remove('disabled');
        }
      } else {
        let step2 = this.getStep2();
        let step1 = this.getStep1();
        let summaryContainer = this.getSummaryContainer();
        if (!summaryContainer) { throw new Error(`Element not found: div#summaryContainer.wizardSummary`); }
        let wizardStep1Container = this.getStep1Container();
        if (!wizardStep1Container) { throw new Error(`Element not found: div#step1Container.wizardStepContainer`); }

        if (step2 && step2.getUIGenerator() &&
            step2.getUIGenerator().getInlineValidationManager().validateInputs(true) &&
            step1 && step1.getSelectedRuleId())
        {
          try {
            await this.validateInOnToNextStep();
          } catch (err) {
            return;
          }
          this.step2Complete = true;
          this.disableCancelButton();
          wizardStep1Container.style.display = "none";
          if (busySpinner) {
            busySpinner.style.display = "block";
          }
          window.scrollTo(0,0);
          try {
            if (! this.isPreviewSummaryOn()) {
              await this.renderEdit();
            }
            if (busySpinner) {
              busySpinner.style.display = "none";
            }
            for (const btn of summaryContainer.querySelectorAll("button.btn")) {
              btn.classList.remove("disabled");
              btn.disabled = false;
            }
            summaryContainer.style.visibility = 'visible';
            this.getWizardSummary().setDefaultFocus();
          } catch (error) {
            if (busySpinner) {
              busySpinner.style.display = "none";
            }
            wizardStep1Container.style.display = "inline-block";
            if (this.isAutoCompleteOn()) {
              this.displayWizardUIonError();
            }
            this.enableCancelButton();
            Util.consoleLogError("onToNextStep", error);
            Util.notify('ERROR', DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [error.message]));
          }
        } else {
          if (this.isAutoCompleteOn()) {
            this.displayWizardUIonError();
          }
          Util.notify('ERROR', I18NStringResource.cmlWizardStep2InvalidValues);
        }
      }
    }

    onStep2CompleteHandler (event) {
      $(document).off("step2complete:createwizard:ccwa");
      try {
        this.displayWizardUIonError (event);
      } catch (error) {
        Util.consoleLogWarning('onStep2CompleteHandler', error);
      }
    }

    displayWizardUIonError (event, data) {
      let step = "";
      let errorMessage = "";
      let exitWizard = false;
      if (data && typeof data === 'object') {
        if (data.step && typeof data.step === 'string') {
          step = data.step;
        }
/*
        if (step === "Step1") {
          exitWizard = true;
        }
 */
        if (data.error && typeof data.error === 'object') {
          let error = data.error;
          errorMessage = DojoString.substitute(I18NStringResource.cmlWizardStepErrorNotice, [step, error.message]);
          Util.consoleLogError(step, error);
        }
      }
      this.setAutoComplete(false);
      const step1SummarySpinner = this.getStep1SummaryBusySpinnerContainer();
      if (step1SummarySpinner) {
        step1SummarySpinner.remove();
      }
      const featuresContainer = this.getConfigInfoContainer();
      if (featuresContainer) {
        featuresContainer.style.display = "none";
      }
      let autoCompleteSpinner = this.getAutoCompleteSpinnerContainer();
      if (autoCompleteSpinner) {
        autoCompleteSpinner.style.display = 'none';
      }
      let step1ContentContainer = this.getStep1ContentContainer();
      if (step1ContentContainer) {
        step1ContentContainer.style.visibility = 'visible';
      }
      let step2Contents = this.getStep2Wrapper();
      if (step2Contents) {
        step2Contents.style.visibility = 'visible';
      }
      this.removeAutoCompleteCSS();
      if (step === "Step1") {
        if (errorMessage) {
          Util.notify('ERROR', errorMessage);
        }
        if (exitWizard) {
          this.onCancelWizard();
        }
      } else {
        let step2 = this.getStep2();
        if (!step2) { throw new Error("Step2 not instantiated"); }
        let uiGenerator = step2.getUIGenerator();
        if (!uiGenerator) { throw new Error("Step2 UIGenerator not instantiated"); }
        uiGenerator.getInlineValidationManager().validateInputs(true);
      }
    }

    showStep1 (event, data) {
      if (event && event.preventDefault) { event.preventDefault(); }
      let step1Container = this.getStep1Container();
      let step2Container = this.getStep2Container();
      let step1GeneratedContentContainer = this.getStep1ContentContainer();
      this.step1Complete = false;
      this.step2Complete = false;
      step1Container.style.display = "block";
      if (data && typeof data === 'object' && data.name && typeof data.name === 'string') {
        let nameContainer = this.getWizardTitleContainer();
        if (nameContainer) {
          nameContainer.textContent = data.name;
        }
      }
      step1Container.style.visibility = "visible";
      step1GeneratedContentContainer.style.visibility = "visible";
      this.getStep1().doLicenseCheck();
    }

    showConfigSummary (event) {
      if (event && event.preventDefault) { event.preventDefault(); }
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      this.getConfigInfoSummary().show();
    }

    dragNotAllowed (event) {
       event.preventDefault();
       event.originalEvent.dataTransfer.dropEffect = 'none';
    }

    showCancelWarningDialogWithoutPropagation (event) {
      if (event) {
        event.stopPropagation();
      }
      this.showCancelWarningDialog(event);
    }

    showCancelWarningGoToAccountsWithoutPropagation (event) {
      if (event) {
        event.stopPropagation();
      }
      this.showCancelWarningAndGoToAccountsPage(event);
    }

    showCancelWarningDialog (event) {
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      this.showWarningDialog (this.onCancelWizard.bind(this));
    }

    showCancelWarningAndGoToAccountsPage (event) {
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      this.showWarningDialog (this.onCancelWizardAndGoToAccountsPage.bind(this));
    }

    resetWizard () {
      this.emptyStep2Content();
      this.emptyStep1Content();
      this.getLocalDataService().clearAll();
    }

    onCancelEdit (event) {
      this.onCancelWizard(event);
      this.remove();
    }

    hideStepsShowSpinner () {
      const stepContainer = this.getStep1Container();
      if (stepContainer) {
        stepContainer.style.visibility = 'hidden';
        const step1Wrapper = this.getStep1Wrapper();
        step1Wrapper.style.visibility = 'hidden';
        const step2Wrapper = this.getStep2Wrapper();
        step2Wrapper.style.visibility = 'hidden';
      }
      const busySpinner = this.getAutoCompleteSpinnerContainer();
      if (busySpinner) {
        busySpinner.style.display = 'flex';
        window.scrollTo(0, 0);
      }
    }

    hideSpinnerShowSteps () {
      const busySpinner = this.getAutoCompleteSpinnerContainer();
      if (busySpinner) {
        busySpinner.style.display = 'none';
      }
      const stepContainer = this.getStep1Container();
      if (stepContainer) {
        stepContainer.style.visibility = 'visible';
        const step1Wrapper = this.getStep1Wrapper();
        step1Wrapper.style.visibility = 'visible';
        const step2Wrapper = this.getStep2Wrapper();
        step2Wrapper.style.visibility = 'visible';
      }
    }

    async onSave (event) {
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      if (this.getStep1().validateAllFields() && this.getStep2().getUIGenerator().getInlineValidationManager().validateInputs(true)) {
        this.hideStepsShowSpinner();
        this.step1Complete = true;
        this.step2Complete = true;
        try {
          let configId = await this.saveConfiguration();
          let data = (configId) ? { configId: configId } : null;
          this.onCancelWizard(event, data);
        } catch (err) {
          this.step1Complete = false;
          this.step2Complete = false;
          const subError = Util.getServerError(err);
          Util.notify("ERROR", DojoString.substitute(I18NStringResource.cmlWizardSummaryInvalidValues, [subError]));
          this.hideSpinnerShowSteps();
        }
      }
    }

    async onLaunch (event) {
      if (event && event.currentTarget) {
        // Register button click with DDUX if we have a button ID
        Util.logDDUXinfoFromClickEvent(event, this.getDataService().logging);
      }
      if (this.getStep1().validateAllFields() && this.getStep2().getUIGenerator().getInlineValidationManager().validateInputs(true)) {
        this.hideStepsShowSpinner();
        this.step1Complete = true;
        this.step2Complete = true;
        let configId;
        try {
          configId = await this.saveConfiguration();
          if (configId) {
            await this.getLocalDataService().workflow.start(configId, this.getProductName());
            let data = { configId: configId };
            this.onCancelWizard(event, data);
          }
        } catch (error) {
          this.handleLaunchError(configId, event, error);
        }
      } else {
        this.enableButtons();
      }
    }

    handleLaunchError (configId, event, error) {
      if (configId) {
        let configValuesMap = this.getConfigInfoSummary().getConfigValuesMap();
        let configName = configValuesMap.get("mlNameInput").displayValue;
        let data = { configId: configId };
        Util.notify("ERROR", DojoString.substitute(I18NStringResource.cmlWizardSummaryUnableToStart, [configName, Util.convertWizardErrorJSONToString(error)]));
        this.onCancelWizard(event, data);
      } else {
        this.step1Complete = false;
        this.step2Complete = false;
        Util.notify("ERROR", DojoString.substitute(I18NStringResource.cmlWizardSummaryInvalidValues, [Util.convertWizardErrorJSONToString(error)]));
        this.hideSpinnerShowSteps();
      }
    }

    onCancelWizard (event, data) {
      data = data || {};
      let element;
      let url;
      let openTarget = "_self";
      if (event) {
        if (event.target) {
          element = event.target;
        }
      }
      if (element) {
        if (element.dataset.href) {
          url = element.dataset.href;
        }
        if (element.dataset.target) {
          openTarget = element.dataset.target;
        }
      }
      if (url && openTarget) {
        data.href = url;
        data.target = openTarget;
      }
      data.wizardInstance = this;
      this.setHasPreviewSummaryError(false);
      this.resetButtonLinks();
      this.resetWizard();
      this.stop();
      $.event.trigger(CreateMATLABWizard.CANCEL_WIZARD_EVENT, data);
    }

    clickAccountsLinkWhenWizardCloses (event) {
      $(document).off("wizardwarningdialogclosed:ccwa", this.clickAccountsLinkWhenWizardCloses);
      let facetsContainer = this.getFacetsContainer();
      if (facetsContainer) {
        let accountsPageLinkContainer = facetsContainer.querySelector('li:nth-child(2)');
        if (accountsPageLinkContainer) {
          let accountsPageLink = accountsPageLinkContainer.querySelector('a');
          if (accountsPageLink) {
            accountsPageLink.click();
          }
        }
      }
    }

    onCancelWizardAndGoToAccountsPage (event, data) {
      data = data || {};
      $(document).on("wizardwarningdialogclosed:ccwa", this.clickAccountsLinkWhenWizardCloses);
      this.onCancelWizard(event, data);
    }

    setDisabledAttributeOnButton (buttonId, disabled) {
      let nextButton = this.getNextButtonByID(buttonId);
      if (nextButton) {
        if (disabled) {
          nextButton.classList.add("disabled");
          nextButton.disabled = true;
        } else {
          nextButton.classList.remove("disabled");
          nextButton.disabled = false;
        }
      }
    }

    disableCancelButton () {
      this.setDisabledAttributeOnButton('cancelWizardButton', true);
    }

    enableCancelButton () {
      this.setDisabledAttributeOnButton('cancelWizardButton', false);
    }

    autoStep2 () {
      if (this.isAutoCompleteOn()) {
        this.onToNextStep();
      }
    }

    autoCompleteStep2 () {
      if (this.isAutoCompleteOn()) {
        // set the first VPC as default
        let vpc = this.getVPCSelect();
        if (vpc && !vpc.selectedIndex) {
          if (Util.selectFirstNonPromptOption(vpc)) {
            vpc.dispatchEvent(new Event("change"));
          }
        }
        // set default password to "password"
        let pwdInput = this.getPasswordInput();
        let confirmPwdInput = this.getConfirmPasswordInput();
        if (pwdInput && confirmPwdInput) {
          const pwd = "";
          pwdInput.value = pwd;
          confirmPwdInput.value = pwdInput.value;
        }

        this.getExtIp();
      }
    }

    async getExtIp() {
        let extIp = this.getClientIPAddressInput();
        if (extIp) {
          let getIp = async function(){
            const ip = await this.getLocalDataService().ui.externalIp();
            if (ip) {
              extIp.value = ip;
            }
          }.bind(this);
          getIp();
        }
    }

    step2DependentOptionsLoaded () {
      this.enableButtons();
      $.event.trigger("step2complete:createwizard:ccwa");
    }

    goToEdit (displayData) {
      let wizardStep1Container = this.getStep1Container();
      let step1Contents = this.getStep1Wrapper();
      let step2Contents = this.getStep2Wrapper();
      this.setPreviewSummary(false);
      if (this.isAutoCompleteOn()) {
        this.setAutoComplete(false);
        let autoSpinner = this.getAutoCompleteSpinnerContainer();
        if (autoSpinner) {
          autoSpinner.style.display = 'none';
        }
        this.setAutoCompleteItems(this.getAllAutoCompleteItems());
        this.toggleAutoCompleteItems();
      }
      if (wizardStep1Container && step2Contents && step1Contents) {
        wizardStep1Container.style.display = 'inline-block';
        step2Contents.style.visibility = 'visible';
        this.showStep1(undefined, displayData);
        this.enableCancelButton();
      }
    }

    start () {
      // remove any previous listeners
      this.stop();
      $(document).on("step1complete:createwizard:ccwa", this.onStep1Change.bind(this));
      if (this.isAutoCompleteOn()) {
        $(document).on("step1complete:createwizard:ccwa", this.autoStep2.bind(this));
        $(document).on("wizardsteperror:ccwa", this.displayWizardUIonError.bind(this));
      }
      document.addEventListener("dependentOptionError:ccwa", this.onDependentOptionError, false);
      $(document).on("AWSKeyPairPrompt_DoAction:ccwa", this.clearCache.bind(this));
    }

    stop () {
      $(document).off("step1incomplete:createwizard:ccwa");
      $(document).off("step1complete:createwizard:ccwa");
      $(document).off("wizardsteperror:ccwa");
      document.removeEventListener("dependentOptionError:ccwa", this.onDependentOptionError, false);
      $(document).off("AWSKeyPairPrompt_DoAction:ccwa");
    }

    /*
     *  Collapse/Expand
    */
    stepIsCollapsed (step) {
      let isCollapsed = false;
      if (step) {
        isCollapsed = step.classList.contains("collapsed");
      }
      return isCollapsed;
    }

    proceedWithStep2Toggle (event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      this.step1Complete = false;
      let step1ContentContainer = this.getStep1ContentContainer();
      if (step1ContentContainer) {
        step1ContentContainer.style.visibility = "visible";
      }
      let firstInputOnForm = this.getStep1().getFirstInputOnForm();
      if (firstInputOnForm) {
        firstInputOnForm.focus();
      }
    }

    emptyStep2Content () {
      if (this.getStep2()) {
        this.getStep2().emptySection2Content();
      }
    }

    emptyStep1Content () {
      if (this.getStep1()) {
        this.getStep1().emptySection1Content();
      }
    }

    setupLinkButtons () {
      let facetsContainer = this.getFacetsContainer();
      if (!facetsContainer) {
        throw new Error("Unable to find facets element");
      }
      let resourcePageLink = facetsContainer.querySelector('li:first-child');
      let accountsPageLink = resourcePageLink.nextElementSibling;
      let linkButtonContainer = document.createElement('li');
      let linkButton = document.createElement('button');
      linkButton.type = 'button';
      linkButton.id = 'goToResourcePageButton';
      linkButton.classList.add('buttonMimicsLink');
      linkButton.textContent = I18NStringResource.computeLinkText;
      linkButtonContainer.append(linkButton);
      linkButton.addEventListener("click", this.showCancelWarningDialog.bind(this), false);
      if (resourcePageLink) {
        resourcePageLink.style.display = "none";
      }
      facetsContainer.prepend(linkButtonContainer);
      if (accountsPageLink) {
        accountsPageLink.addEventListener("click", this.showCancelWarningGoToAccountsWithoutPropagation, false);
      }
      let logoButton = this.getLogoButton();
      if (logoButton) {
        logoButton.addEventListener("click", this.showCancelWarningDialogWithoutPropagation, false);
      }
    }

    resetButtonLinks () {
      let facetsContainer = this.getFacetsContainer();
      if (!facetsContainer) {
        throw new Error("Unable to find facets element");
      }
      let linkButtons = facetsContainer.querySelectorAll('button.buttonMimicsLink');
      if (linkButtons && linkButtons.length) {
        for (let button of linkButtons) {
          let parent = button.parentElement;
          if (parent) {
            facetsContainer.removeChild(parent);
          }
        }
        let resourcePageLink = facetsContainer.querySelector('li:first-child');
        if (resourcePageLink && resourcePageLink.style.display === 'none') {
          resourcePageLink.style.display = 'table-cell';
        }
        let accountsPageLink = resourcePageLink.nextElementSibling;
        if (accountsPageLink) {
          accountsPageLink.removeEventListener("click", this.showCancelWarningGoToAccountsWithoutPropagation, false);
        }
      }
      let logoButton = this.getLogoButton();
      if (logoButton) {
        logoButton.removeEventListener("click", this.showCancelWarningDialogWithoutPropagation, false);
      }
    }

    /*
     * UI rendering
     */
    async render () {
      if (this.getInitialCreateButtonClickSpinner()) {
        this.getInitialCreateButtonClickSpinner().remove();
      }

      let templateParameters = {};  // new object
      templateParameters.wizardTitle = I18NStringResource.cmlWizardWizardTitle;
      templateParameters.wizardExplanation = I18NStringResource.cmlWizardExplanation1;
      templateParameters.step1Title = I18NStringResource.cmlWizardStep1Title;
      templateParameters.step1Legend = I18NStringResource.cmlWizardStep1Legend;
      templateParameters.nextButtonText = I18NStringResource.cmlWizardNextButtonText;
      templateParameters.cancelEditButtonText = I18NStringResource.cmlWizardCancelEditButtonText;
      templateParameters.cancelEditButtonTooltip = I18NStringResource.cmlWizardCancelEditButtonTooltip;
      templateParameters.saveButtonText = I18NStringResource.cmlWizardSaveButtonText;
      templateParameters.saveButtonTooltip = I18NStringResource.cmlWizardSaveButtonTooltip;
      templateParameters.launchButtonText = I18NStringResource.cmlWizardLauchButtonText;
      templateParameters.launchButtonTooltip = I18NStringResource.cmlWizardLaunchButtonTooltip;
      templateParameters.releaseLicenseWarningText = I18NStringResource.releaseNeedsLicenseWarning;
      templateParameters.autoComplete = this.isAutoCompleteOn();
      let htmlResult = this.template(templateParameters);
      this.$el.empty().html(htmlResult);
      this.start();

      // Add fake link button
      this.setupLinkButtons();

      // config info summary
      this.renderConfigInfo();
      // async method

      if (this.productInfo) {
        try {
          this.setPreviewSummary(true);
          await this.renderEdit(true);
        } catch (error) {
          Util.consoleLogError("render", error);
          if (error.name === 'InvalidPrerequisiteError') {
            Util.notify('ERROR', DojoString.substitute(I18NStringResource.cmlWizardMissingCreatePrerequisite, [error.message]));
            this.onCancelWizard();
          } else {
            this.setHasPreviewSummaryError(true);
            this.setPreviewSummary(false);
            $(document).on("step2complete:createwizard:ccwa", this.onStep2CompleteHandler.bind(this));
            this.setAutoComplete(false);
            await this.renderStep1();
            this.processError(error);
          }
        }
      } else {
        this.renderStep1();
      }
    }

    processError (error) {
      const token = I18NStringResource.cmlWizardExpectedValueMissing.substr(0, I18NStringResource.cmlWizardExpectedValueMissing.indexOf("$"))
      if (error && error.message && error.message.startsWith(token)) {
        Util.notify("ERROR", error.message);
      } else if (error && error.message && error.message.indexOf(CCWA_Constants.CLOUD_CENTER_VAULT_INTERNAL_ERROR)>=0) {
        Util.notify("ERROR", I18NStringResource.dataServiceErrorCloudCenterVaultInternal);
      } else {
        Util.notify("ERROR", DojoString.substitute(I18NStringResource.cmlWizardSummaryInvalidValues, [Util.convertWizardErrorJSONToString(error)]));
      }
    }

    renderConfigInfo () {
      let container = this.getConfigInfoContainer();
      if (container) {
        container.appendChild(this.getConfigInfoSummary().el);
        this.getConfigInfoSummary().render();
      }
    }

    newCreateMatlabWizardStep1 () {
      const step1 = new CreateMatlabWizardStep1({
        wizardStepName: "Step1",
        dataService: this.getLocalDataService(),
        productName: this.getProductName(),
        autoComplete: this.isAutoCompleteOn(),
        configInfoSummary: this.getConfigInfoSummary(),
        wrapperElementClassName: "step1Wrapper",
        rulesManager: this.getRulesManager(),
        validFieldHandler: this.enableButtons.bind(this),
        invalidFieldHandler: this.disableButtons.bind(this),
        defaultRule: this.getDefaultRule()
      });
      if (this.getDesiredRuleValues()) {
        const step1Defaults = this.getDesiredRuleValues().step1;
        if (step1Defaults) {
          step1.setDesiredStepValues(step1Defaults);
        }
      }
      return step1;
    }

    async renderStep1 () {
      let step1 = this.newCreateMatlabWizardStep1();
      this.setStep1(step1);
      let step1ContainerElement = this.getStep1ContentContainer();
      let buttonsContainer = this.getButtonsContainer();
      if (step1ContainerElement && buttonsContainer) {
        step1ContainerElement.style.display = 'block';
        buttonsContainer.style.display = 'block';
        step1ContainerElement.appendChild(step1.el);
        // show the UI
        let busySpinner = this.getStep1BusySpinnerContainer();
        let step1ContentContainer = this.getStep1ContentContainer();
        if (step1ContentContainer) {
          step1ContentContainer.style.display = 'none';
        }
        await step1.renderStep();
        if (busySpinner) {
          busySpinner.style.display = 'none';
        }
        if (step1ContentContainer) {
          step1ContentContainer.style.display = 'block';
        }
        let firstInputOnForm = this.step1.getFirstInputOnForm();
        if (firstInputOnForm) {
          firstInputOnForm.focus();
        }
      }
    }

    async renderStep2 () {
      let step = this.getStep1();
      if (!step) { return; }
      if (await step.validateAllFields()) {
        let step1Container = this.getStep1Container();
        let step2Container = this.getStep2Container();
        step2Container.style.display = "block";
        let hiddenFieldData = [];
        let stepFieldData = step.getFieldData();
        let fieldIds = Object.keys(stepFieldData);
        for (let fieldId of fieldIds) {
          if (fieldId.startsWith("mw-")) {
            let hiddenField = {
              id: fieldId,
              name: fieldId,
              value: stepFieldData[fieldId]
            };
            hiddenFieldData.push(hiddenField);
          }
        }
        let searchCriteria = step.getStepDataAsRuleInfo();
        // look for and remove any wrapper elements from previous calls to create step2
        let step2Wrappers = this.getAllStep2Wrappers();
        if (step2Wrappers.length) {
          step2Wrappers.forEach(wrapper => wrapper.remove());
        }
        const step1 = this.getStep1();
        let step2 = new CreateMatlabWizardStep2({
          wizardStepName: "Step2",
          dataService: this.getLocalDataService(),
          productName: this.getProductName(),
          autoComplete: this.isAutoCompleteOn(),
          searchCriteria: searchCriteria,
          hiddenFieldData: hiddenFieldData,
          configInfoSummary: this.getConfigInfoSummary(),
          wrapperElementClassName: "step2Wrapper",
          validFieldHandler: this.enableButtons.bind(this),
          invalidFieldHandler: this.disableButtons.bind(this),
          rulesManager: this.getRulesManager(),
          savedValues: this.getSavedStep2Values(),
          updateSavedStep1ValueMethod: step1.updateSavedStep1ValueMethod(),
          getSavedStep1ValuesMethod: step1.getSavedStep1ValuesMethod(),
          onStep1ChangeMethod: this.onStep1Change.bind(this),
          cloudPlatform: stepFieldData.cloudProvider
        });
        if (this.getDesiredRuleValues() && this.getDesiredRuleValues().step2) {
          step2.setDesiredStepValues(this.getDesiredRuleValues().step2);
        }
        this.setStep2(step2);
        let step2ContentContainer = this.getStep2ContentContainer();
        step2ContentContainer.appendChild(step2.el);

        this.step1Complete = true;

        if (this.isAutoCompleteOn() && step2ContentContainer) {
          step2ContentContainer.classList.add("autoComplete");
        }
        let busySpinner = this.getStep2BusySpinnerContainer();
        await step2.renderStep();
        this.restoreStep2Values();
        if (busySpinner) {
          busySpinner.style.display = 'none';
        }
        let firstInputOnForm = this.getStep2().getFirstInputOnForm();
        if (firstInputOnForm) {
          firstInputOnForm.focus();
        }
        let instanceTypeSelect = this.getInstanceTypeSelect();
        if (instanceTypeSelect) {
          this.debouncedOptionsLoaded = _.debounce(this.step2DependentOptionsLoaded, 1000).bind(this);
          instanceTypeSelect.addEventListener("optionsLoaded", this.debouncedOptionsLoaded, false);
        }
        this.autoCompleteStep2();
        if (this.hasPreviewSummaryError()) {
          this.setHasPreviewSummaryError(false);
          this.setAutoComplete(false);
          this.hideSpinnerShowSteps();
          const items = document.querySelectorAll(".autoComplete");
          items.forEach(item => item.classList.remove("autoComplete"));
          this.getStep1().validateAllFields();
          this.getStep2().getUIGenerator().getInlineValidationManager().validateInputs(true);
        }
      }
    }
    async saveConfiguration () {
      return this.validateOnlyOrSaveConfiguration(false);
    }

    async validateOnlyConfiguration () {
      return this.validateOnlyOrSaveConfiguration(true);
    }

    async validateOnlyOrSaveConfiguration (validateOnly) {
      let summary = this.getSummaryContainer();
      let busySpinner = this.getAutoCompleteSpinnerContainer();
      let originalValues = {};

      if (!validateOnly) {
        if (summary) {
          originalValues.sumVis = summary.style.visibility;
          originalValues.sumDisp = summary.style.display;
          summary.style.visibility = "hidden";
          summary.style.display = "none";
        }
        if (busySpinner) {
          originalValues.sumSpinDisp = busySpinner.style.display;
          busySpinner.style.display = "flex";
        }
      }

      let configId = I18NStringResource.unknownValue;
      let rulesId = I18NStringResource.unknownValue;
      let params = {};
      let cloudProvider = "aws" //default value
      if (!this.step1Complete || !this.step2Complete) {
        //direct save, collect params from defaults
        rulesId = this.quickSaveRulesID;
        params = this.quickSaveParams;
      } else {
        //save after wizard, collect params from wizard
        let step2 = this.getStep2();
        let step1 = this.getStep1();
        let section2Selectors = step2.getUIGenerator().getInputSelectorMap();
        let entries = section2Selectors.values();
        let entry = entries.next();
        cloudProvider = step1.getFieldData().cloudProvider
        while (!entry.done) {
          let data = entry.value;
          let fieldId = data.id;
          let element = this.el.querySelector(data.selector);
          let fieldValue;
          if (element) {
            fieldValue = element.value;
          }
          params[fieldId] = fieldValue;
          entry = entries.next();
        }
        rulesId = step1.getSelectedRuleId();
      }
      if (params) {
        if(cloudProvider === "azure") {
          let accountId;
          let subscriptionId;
          // mw-credential-id needs to be split into the real "mw-credential-id" - the account id, and "mw-subscriptionId"
          ({accountId, subscriptionId} = Util.splitCredIdForAzure(params["mw-credential-id"]))
          params["mw-credential-id"] = accountId
          params["mw-subscription-id"] = subscriptionId
        } else if(cloudProvider === "aws") {
          await this.getLocalDataService().getPlatformDataService('aws').getEC2Service().getSSHKey(params["mw-credential-id"], params["mw-cloud-location"]);
        }
      }
      let resultJson;
      try {
        let dataService;
        let ruleManager = this.getRulesManager();
        if (ruleManager) {
          dataService = ruleManager.getDataService();
        } else {
          dataService = this.getLocalDataService();
        }
        resultJson = await dataService.workflow.create(rulesId, params, validateOnly, this.getProductName());
      } catch (e) {
        if (busySpinner) {
          busySpinner.style.display = originalValues.sumSpinDisp;
        }
        if (summary){
          summary.style.visibility = originalValues.sumVis;
          summary.style.display = originalValues.sumDisp;
        }
        throw e;
      }

      let resultObj = {};
      if (resultJson && typeof resultJson === "string") {
        resultObj = JSON.parse(resultJson);
      } else {
        resultObj = resultJson;
      }
      if (resultObj && resultObj.id) {
        configId = resultObj.id;
      }
      if (!validateOnly && configId === I18NStringResource.unknownValue) {
        if (busySpinner) {
          busySpinner.style.display = originalValues.sumSpinDisp;
        }
        if (summary){
          summary.style.visibility = originalValues.sumVis;
          summary.style.display = originalValues.sumDisp;
        }
      }
      return configId;
    }

    getStep2Values () {
      let returnValue = null;
      if (this.step1Complete) {
        const ruleId = this.getStep1().getSelectedRuleId();
        const step2Values = this.getStep2().getStepValues();
        step2Values.ruleId = ruleId;
        returnValue = step2Values;
      }
      return returnValue;
    }

    updateElementFromParent (element, step2Wrapper) {
      const classes = element.classes.join('.');
      const parent = step2Wrapper.querySelector(`div.${classes}`);
      if (parent) {
        const foundElements = parent.getElementsByTagName(element.tagName);
        if (foundElements && foundElements.length === 1) {
          const child = foundElements[0];
          child.value = element.value;
        }
      }
    }

    restoreStep2Values (delayed = false) {
      const step2Values = this.getSavedStep2Values();
      const platformChanged = this.getStep1() ? this.getStep1().hasPlatformChanged() : true;
      if (step2Values && !platformChanged) {
        const visibleValues = step2Values.visible;
        const IDs = Object.keys(visibleValues);
        const step2Wrapper = this.getStep2Wrapper();
        if (step2Wrapper && visibleValues && IDs) {
          IDs.forEach((id) => {
            const element = step2Wrapper.querySelector(`#${id}`);
            try {
              if (element) {
                // check for radio/checkbox
                if (element.tagName.toUpperCase() === 'FIELDSET') {
                  const choice = element.querySelector(`input[name="${id}"][value="${visibleValues[id].value}"]`);
                  if (choice) {
                    choice.checked = true;
                  }
                } else {
                  if (!delayed && !visibleValues[id].classes) {
                    element.value = visibleValues[id].value;
                  }
                }
              } else {
                if (visibleValues[id].classes) {
                  this.updateElementFromParent(visibleValues[id], step2Wrapper);
                }
              }
            } catch (error) {
              Util.consoleLogWarning(`restoreStep2Values`, error);
            }
          });
        }
      }
    }

    async prepopulateWithRules () {
      const rm = this.getRulesManager();
      const pi = this.getProductInfo();
      let returnData = {};

      let ruleId = null;
      let credentialTypeId = "";
      let platform = "";
      let tmpRuleId, tmpLocation, tmpCredId, tmpCredTypeId, tmpDescription, tmpRuleObj;

      if (pi) {
        if (!pi || typeof pi !== 'object' || !('ruleIdArray' in pi) || !('credentialTypeIdMap' in pi)) {
          throw new TypeError("Invalid productInfo object");
        }
        let rulesArray = pi.ruleIdArray;
        let credTypeIdMap = pi.credentialTypeIdMap;

        // If the desired rule ID is specified, use it and get the credential Type ID that corresponds to it.
        if (this.getDesiredRuleValues() && this.getDesiredRuleValues().step1 && this.getDesiredRuleValues().step1.ruleId) {
          ruleId = this.getDesiredRuleValues().step1.ruleId;
          credentialTypeId = credTypeIdMap.get(ruleId);
        } else { // otherwise, look for a default rule
          const mostRecentName = `mostrecent__${this.getProductName().toLowerCase()}`;
          const mostRecentlyUsedRuleId = pi[mostRecentName];
          if (mostRecentlyUsedRuleId && (rulesArray.indexOf(pi[mostRecentName]) >= 0) && credTypeIdMap.get(pi[mostRecentName])) {
            ruleId = mostRecentlyUsedRuleId;
            credentialTypeId = credTypeIdMap.get(pi[mostRecentName]);
          } else {
            [tmpRuleId, tmpLocation, tmpCredId, tmpCredTypeId, tmpDescription, tmpRuleObj] = await this.getLocalDataService().workflow.defaultRuleAndAdditionalInfoByProduct(this.getProductName().toLowerCase());
            if (tmpRuleObj && tmpRuleObj.params) {
              credentialTypeId = tmpCredTypeId;
              ruleId = tmpRuleId;
            } else {
              credentialTypeId = credTypeIdMap.get(rulesArray[0]);
            }
          }
        }

        platform = Util.getPlatformFromCredentialTypeId(credentialTypeId);
      }
      let rule = await rm.getRulesWithDefaults(this.getProductName(), ruleId, credentialTypeId);
      if (!rule) {
        // clear cache and try again
        this.getLocalDataService().clear("workflow/defaultRuleByProduct");
        rule = await rm.getRulesWithDefaults(this.getProductName(), ruleId, credentialTypeId);
      }

      if (rule) {
        // If we have desired step2 values, convert them to savedStep2Values so restoreStep2Values can use them.
        if (this.getDesiredRuleValues() && this.getDesiredRuleValues().step2 && rule.body && rule.body.params) {
          const desiredStep2Values = this.getDesiredRuleValues().step2;
          const bodyParams = rule.body.params;
          const step2Values = {};
          for (const param of bodyParams) {
            if (desiredStep2Values[param.id]) {
              param.config.default = desiredStep2Values[param.id];
              const elementType = param.config.ui_element.type;
              if (elementType === 'checkbox') {
                const settingsKey = param.constraints.setting_include;
                const settings = rule.body.settings;
                let checkboxValues = [];
                if (settingsKey && settings && settings[settingsKey]) {
                  for (const key in  settings[settingsKey]) {
                   checkboxValues.push(String(key));
                  }
                  if (checkboxValues.length !== 2) {
                    checkboxValues = null;
                  }
                }
                if (checkboxValues) {
                  let isChecked = false;
                  if (checkboxValues.indexOf(desiredStep2Values[param.id]) === 1) {
                    isChecked = true;
                  }
                  if (isChecked) {
                    param.config.ui_element.checked = true;
                    param.config.ui_element.value = checkboxValues[1];
                  } else {
                    param.config.ui_element.value = checkboxValues[0];
                  }
                } else {
                  param.config.ui_element.value = desiredStep2Values[param.id];
                }
              }
            }
          }
        }

        this.setDefaultRule(rule);
        this.quickSaveParams = {};
        let paramIDsWithError = [];
        for (const param of rule.body.params){
          this.quickSaveParams[param.id] = param.config.default;
          // quick check that defaults exist for all required fields
          if (param.constraints.required && !param.config.default && !UIElementUtil.QUERY_TYPES.includes(param.type)) {
            paramIDsWithError.push(param.id);
          }
        }
        if (paramIDsWithError.length) {
          const errorIDs = paramIDsWithError.join(", ");
          throw new Error(DojoString.substitute(I18NStringResource.cmlWizardExpectedValueMissing, [errorIDs]));
        }

        this.quickSaveRulesID = rule.id;
        returnData.entitled = rule.entitled;
        this.getConfigInfoSummary().mapInputIdToSummaryId("mlNameInput", "productName", false, true, I18NStringResource.cmlWizardStep1ConfigProductLabel, rule.product.toUpperCase());
        this.getConfigInfoSummary().mapInputIdToSummaryId("releaseSelector", "productRelease", true, false, I18NStringResource.cmlWizardStep1ConfigReleaseLabel, rule.version);
        this.getConfigInfoSummary().mapInputIdToSummaryId("cloudChoice", "cloudPlatform", true, false, I18NStringResource.cmlWizardStep1ConfigCloudPlatformLabel, rule.cloud_provider);
        this.getConfigInfoSummary().mapInputIdToSummaryId("osChoice", "osName", true, false, I18NStringResource.cmlWizardStep1ConfigOsLabel, rule.operating_system);

        //overriding default name with default as built by ui
        const baseName = CreateMatlabWizardStep1.getResourceNameFromProduct(rule.product, rule.version, rule.cloud_provider);
        const name = this.newCreateMatlabWizardStep1().generateUniqueMachineName(rule.product, baseName);
        this.quickSaveParams["mw-name"] = name;
        this.getConfigInfoSummary().mapInputIdToSummaryId("mlNameInput", "productNickname", true, false, I18NStringResource.cmlWizardStep1ConfigProductNicknameLabel, name);

        paramIDsWithError = [];
        for (const param of rule.body.params){
          if (param.constraints.required && !param.config.default && !UIElementUtil.QUERY_TYPES.includes(param.type)) {
            paramIDsWithError.push(param.id);
          }
          if (paramIDsWithError.length) {
            continue; //error scenario, stop processing
          }
          // let credentialDescription = description;
          let credentialDescription = (tmpDescription)?tmpDescription:"";
          switch (param.id) {
            // case "mw-name": //overriding default name with default as built by ui
            //   this.getConfigInfoSummary().mapInputIdToSummaryId("mlNameInput", "productNickname", true, false, I18NStringResource.cmlWizardStep1ConfigProductNicknameLabel, param.config.default);
            // break;
            case "mw-credential-id":
              if (!credentialDescription) {
                credentialDescription = await this.getLocalDataService().ui.getCredentialDescription(platform, param.config.default);
              }
              this.getConfigInfoSummary().mapInputIdToSummaryId("credentialSelector", "credential", true, false, I18NStringResource.cmlWizardStep1ConfigAuthenticatedAsLabel, credentialDescription);
            break;

            case "mw-cloud-location":
              this.getConfigInfoSummary().mapInputIdToSummaryId("cloudLocationSelector", "cloudLocationName", true, false, I18NStringResource.cmlWizardStep1ConfigRegionLabel, param.config.default);
            break;
          }
        }
        if (paramIDsWithError.length) {
          const errorIDs = paramIDsWithError.join(", ");
          throw new Error(DojoString.substitute(I18NStringResource.cmlWizardExpectedValueMissing, [errorIDs]));
        }
        //apply after using step1 values in case anything overwrites
        for (const param of rule.body.params){
          //mapInputIdToSummaryId (inputId, summaryId, isRequired, isHighlighted, labelText, initialValue)
          this.getConfigInfoSummary().mapInputIdToSummaryId(param.id, param.id, param.constraints.required, false, param.config.ui_element.label, param.config.default);
        }
      }
      return returnData;
    }

    addMissingFieldsToMap (configValuesMap) {
      if (!configValuesMap || typeof configValuesMap.set !== 'function') {
        throw new TypeError("Invalid configValuesMap argument");
      }
      let pwdInput = this.getPasswordInput();
      let confirmPwdInput = this.getConfirmPasswordInput();
      configValuesMap.set('Password', {rawValue: pwdInput.value, displayValue: pwdInput.value});
      configValuesMap.set('Confirm Password', {rawValue: confirmPwdInput.value, displayValue: confirmPwdInput.value});
      let hiddenFields;
      if (this.getStep2() && this.getStep2().getHiddenFieldData()) {
        hiddenFields = this.getStep2().getHiddenFieldData();
        for (let field of hiddenFields) {
          configValuesMap.set(field.id, {rawValue: field.value, displayValue: field.value});
        }
      }
    }

    async renderEdit (useDefaultRules=false) {
      let rulesData;
      if (useDefaultRules) {
        rulesData = await this.prepopulateWithRules();
      }
      if (rulesData && rulesData.error) {
        useDefaultRules = false; //this means there's missing data that going through Step 1-2 will reveal
      }
      let configValuesMap = this.getConfigInfoSummary().getConfigValuesMap();
      let licenseRequired = true;
      if (useDefaultRules) {
        licenseRequired = !rulesData.entitled; //not entitled
      } else {
        if (this.getStep1()) {
          licenseRequired = this.getStep1().selectedReleaseRequiresLicense();
        } else {
          licenseRequired = true;
        }
      }
      let ruleId = (this.getStep1() && this.getStep1().getSelectedRuleId()) ? this.getStep1().getSelectedRuleId() : this.quickSaveRulesID;
      if (this.getStep2() && !useDefaultRules) {
        this.addMissingFieldsToMap(configValuesMap);
      }
      let credentialId = "";
      if (configValuesMap.get("mw-credential-id")) {
        credentialId = configValuesMap.get("mw-credential-id").rawValue;
      }
      let location = "";
      if (configValuesMap.get("mw-cloud-location")) {
        location = configValuesMap.get("mw-cloud-location").rawValue;
      }
      // if (this.getDesiredRuleValues() && this.getDesiredRuleValues().step2) {
      //   const values = this.getDesiredRuleValues().step2;
      //   credentialId = `${values["mw-credential-id"]}`;
      //   if (values.platform === 'azure' && values["mw-subscription-id"]) {
      //     credentialId += `(${values["mw-subscription-id"]})`;
      //   }
      //   location = values["mw-cloud-location"];
      // }
      if (!credentialId || !location) {
        const error = new Error(I18NStringResource.computeResourcePageActionShareResourceSettingsFailure);
        if (this.getDesiredRuleValues() && (this.getDesiredRuleValues().step1 || this.getDesiredRuleValues().step2)) {
          error.name = 'InvalidPrerequisiteError';
        }
        throw error;
      }
      let uiResults = await this.getLocalDataService().ui.listSection2UIElementsByRuleId(ruleId, credentialId, location);
      const autoCompleteSpinner = this.getAutoCompleteSpinnerContainer();
      if (autoCompleteSpinner && !useDefaultRules) {
        autoCompleteSpinner.style.display = "none";
      }
      if (useDefaultRules) {
        await this.renderStep1();
        this.goToEdit({
          name: "",
          cloudChoice: configValuesMap.get("cloudChoice").rawValue
        });
      }
    }

    showWarningDialog (proceedMethod) {
      var dialogTitle = I18NStringResource.wizardWarningDialogTitle;
      let proceedPrompt = I18NStringResource.wizardWarningDialogProceedPrompt;
      let dialogPromptText = I18NStringResource.wizardWarningDialogMainWarning;
      //show modal dialog for confirmation
      let wizardWarningModalDialog = new WizardWarningDialog({
        title: dialogTitle,
        text: dialogPromptText,
        prompt: proceedPrompt,
        actionFn: proceedMethod.bind(this)
      });
      wizardWarningModalDialog.show();
    }

    dependentOptionErrorHandler (event) {
      if (event && event.detail) {
        const detail = event.detail;
        Util.consoleLogWarning('dependentOptionErrorHandler', `queryType: ${detail.queryType}, error message: ${detail.errorMessage}`);
      }
      try {
        this.getStep2().getUIGenerator().getInlineValidationManager().validateInputs(true);
        Util.notify('ERROR', I18NStringResource.cmlWizardStep2InvalidValues);
      } catch (error) {
        // this can happen in a unit test.
        if (this.getStep2()) {
          Util.consoleLogWarning('dependentOptionErrorHandler', error);
        }
      }
    }

  }

  return CreateMATLABWizard;
});
