/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "templates/statusAndCountdownTemplate",
  "service/cloudStatus",
  "service/cloudResource"
], function (
   _,
   $,
   Backbone,
   StatusAndCountdownTemplate,
   CloudStatus,
   CloudResource
) {

  /*
    expected external element: <div class="statusAndCountdownContainer"></div>
  */
  class StatusAndCountdown extends Backbone.View {

    constructor (params) {
      super(params);
      this.template = StatusAndCountdownTemplate;
      // defaults
      this.useProgressSpinner = false;
      this.supportsTermPolicy = true;
      // override defaults as specified in params
      if (params && typeof params === 'object') {
        if (('useProgressSpinner' in params) && params.useProgressSpinner === true) {
          this.useProgressSpinner = true;
        }
        if ('supportsTermPolicy' in params) {
          this.supportsTermPolicy = !!params.supportsTermPolicy; // convert to boolean
        }
      } else if ( (params && typeof params !== 'object') || params === null ) {
        throw new TypeError("Invalid params");
      }
    }

    // getters and setters
    shouldUseProgressSpinner () { return this.useProgressSpinner; }
    getSupportsTermPolicy () { return this.supportsTermPolicy; }

    render () {
      const templateParams = {
        useProgressSpinner: this.shouldUseProgressSpinner()
      };
      const html = this.template(templateParams);
      this.$el.empty().html(html);
    }

    getCurrentStatus() {
      let status = CloudStatus.Enum.UNKNOWN;;
      try {
        const statusIndicator = this.el.querySelector('div.resource-status.status');
        if (statusIndicator) {
          const statusNameContainer = statusIndicator.querySelector('div.statusNameContainer');
          if (statusNameContainer) {
            const statusDisplayName = statusNameContainer.textContent;
            if (statusDisplayName) {
              status = CloudStatus.getStatusByDisplayText(statusDisplayName);
            }
          }
        }
      } catch (error) {
        /* do nothing */
      }
      return status;
    }

    renderStatus (status, percent, alternateStatusText) {
      if (!status || !(CloudStatus.isValidStatus(status))) {
        status = CloudStatus.Enum.UNKNOWN;
      }
      if (!percent) {
        percent = 0.0;
      }
      if (Number.isNaN(parseFloat(percent))) {
        throw new TypeError("Invalid percent argument");
      }
      let statusIndicator = this.el.querySelector('div.resource-status.status');
      if (statusIndicator) {
        let progressbar = statusIndicator.querySelector('div.progressbar');
        let statusNameContainer = statusIndicator.querySelector('div.statusNameContainer');
        if (alternateStatusText) {
          statusNameContainer.textContent = alternateStatusText;
        } else {
          statusNameContainer.textContent = CloudStatus.getStatusDisplayText(status);
        }
        statusIndicator.classList = "resource-status status"; // reset
        statusIndicator.classList.add(CloudStatus.getStatusClass(status));
        let classname = StatusAndCountdown.getProgressBarClassname(status);
        let progressBar = this.el.querySelector("div.resource-status.status > div.progressbar");
        if (!progressBar) {
          throw new Error("Unable to find progress bar for status");
        }
        StatusAndCountdown.setStatusProgress(progressBar, status, percent);
      }
    }

    async updateCountdownTimer (configId, status, cloudResource) {
      if (!this.getSupportsTermPolicy()) {
        return;
      }
      if (!configId || typeof configId !== 'string') {
        throw new TypeError("Invalid configId argument");
      }
      if (cloudResource && !(cloudResource instanceof CloudResource)) {
        throw new TypeError("Invalid cloudResource argument");
      }
      if (!status) { status = CloudStatus.Enum.UNKNOWN; }
      if (status !== CloudStatus.Enum.TERMINATED) {
        // termination policy dropdown is external to this widget
        // so we look for it in the document and remove the "hide" class to show it.
        // Reference: timerDropdown.js
        const timerDropdown = document.querySelector('div.countdownIndicator.form-inline.countdown-form-inline');
        if (timerDropdown) {
          timerDropdown.classList.remove("hide");
        }
      }
      if (cloudResource) {
        let countdownContainerSelector = 'div.statusAndCountdownContainer';
        const countdownContainer = this.el;
        if (status === CloudStatus.Enum.RUNNING) {
          const timerId = Date.now();
          this.el.dataset.timerId = timerId;
          countdownContainerSelector = `${countdownContainerSelector}[data-timer-id='${timerId}']`;
          await cloudResource.startCountdown(countdownContainerSelector);
        } else {
          cloudResource.stopCountdown(countdownContainerSelector);
          countdownContainer.removeAttribute('data-timer-id');
        }
      }
    }

    static getProgressBarClassname (status) {
      let classname = "";
      switch (status) {
        case CloudStatus.Enum.RESUMING:
        case CloudStatus.Enum.STARTING:
        case CloudStatus.Enum.STARTED:
          classname = "starting";
          break;

        case CloudStatus.Enum.PAUSING:
        case CloudStatus.Enum.TERMINATING:
          classname = "stopping";
          break;

        case CloudStatus.Enum.RUNNING:
        case CloudStatus.Enum.TERMINATED:
        case CloudStatus.Enum.PAUSED:
        default:
          classname = "";
      }
      return classname;
    }

    static setStatusProgress (progressBar, status, percent) {
      let classname = StatusAndCountdown.getProgressBarClassname(status);
      if (!progressBar || typeof progressBar !== 'object') {
        throw new TypeError("Invalid progressBar argument");
      }
      if (Number.isNaN(parseInt(percent))) {
        throw new TypeError("Invalid percent argument");
      }
      progressBar.classList = `progressbar`;
      if (classname) {
        progressBar.classList.add(classname);
      }
      if (classname && percent) {
        let percentValue = Math.min(parseInt(percent), 99);
        progressBar.style.width = `${percentValue}%`;
      }
    }

  }
  return StatusAndCountdown;
});
